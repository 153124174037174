@use "sass:math";
@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

#page-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background: -webkit-linear-gradient( 90deg, rgb(1,60,136) 0%, rgb(0,93,136) 50%, rgb(1,129,140) 100%);
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
    z-index: 10;
    &.active {
        opacity: 1;
        visibility: visible;
    }
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 14px;
        height: 9px;
        background: url(/assets/img/common/icon_arrow_up.png) center/contain no-repeat;
        transition: $transition;
    }
    @include pc {
        &:hover {
            &::before {
                top: calc(50% - 5px);
            }
        }
    }
    @include sp {
        bottom: 15px;
        right: 15px;
        width: 40px;
        height: 40px;
    }
}
.l-footer * {
    font-family: "NotoSansJP","Yu Gothic","Yu Gothic Medium","游ゴシック Medium",YuGothic,"游ゴシック体","ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
    line-height: 1.5;
    letter-spacing: 0.05rem;
}
.l-footer {
    background: #f4f4f8;
    img {
        width: 100%;
        height: auto;
        image-rendering: -webkit-optimize-contrast;
        vertical-align: bottom;
    }
    &__inner {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 30px;
    }
    &__upper {
        padding: 75px 0 35px;
        display: flex;
        &__left {
            width: 225px;
            margin-right: 6%;
        }
        &__right {
            width: calc(94% - 225px);
        }
        &__products {
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            &__list {
                font-size: 1.7rem;
                font-weight: 500;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
                &:nth-child(n + 2) {
                    margin-top: 30px;
                }
            }
            &__link {
                transition: $transition;
                &:hover {
                    color: $c-accent;
                }
            }
            &__inner {
                display: flex;
                align-items: center;
                position: relative;
                &::before {
                    content: "/";
                    display: inline-block;
                    color: #222;
                    font-size: 1.6rem;
                    margin-left: 20px;
                }
                &__list {
                    font-size: 1.4rem;
                    color: $c-sub;
                    margin-left: 20px;
                    font-weight: 400;
                }
                &__link {
                    @include link-underline {
                        &::before {
                            background: $c-sub;
                        }
                    }
                }
            }
        }
        &__nav {
            margin-top: 30px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-left: -15px;
            &__list {
                font-size: 1.6rem;
                font-weight: 500;
                margin: 0 15px 5px;
            }
            &__link {
                transition: $transition;
                &:hover {
                    color: $c-accent;
                }
            }
        }
    }
    &__logo {
        width: 170px;
        margin: 0 auto;
    }
    &__socialmedia {
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__list {
            width: 32px;
        }
        &__link {
            transition: $transition;
            &:hover {
                opacity: 0.75;
            }
        }
    }
    &__under {
        border-top: 1px solid #cccccc;
        padding: 40px 0 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__nav__block {
            display: flex;
            width: calc(100% - 300px);
        }
        &__nav {
            display: flex;
            &:nth-child(2) {
                margin-left: 30px;
            }
            &__list {
                &:nth-child(n + 2) {
                    margin-left: 30px;
                }
            }
            &__link {
                font-size: 1.3rem;
                color: #888888;
                @include link-underline {
                    &:before {
                        background: #888888;
                    }
                }
            }
        }
        .l-footer__copy {
            width: 300px;
            font-size: 1.3rem;
            color: #888888;
            text-align: right;
        }
    }
    @include pcmin1200 {
        &__inner {
            padding: 0 vwm(30px);
        }
        &__upper {
            padding: vwm(75px) 0 vwm(35px);
            &__left {
                width: vwm(225px);
                margin-right: 6%;
            }
            &__right {
                width: calc(94% - vwm(225px));
            }
            &__products {
                margin-top: vwm(20px);
                &__list {
                    font-size: vwm(17px);
                    &:nth-child(n + 2) {
                        margin-top: vwm(30px);
                    }
                }
                &__inner {
                    &::before {
                        font-size: vwm(16px);
                        margin-left: vwm(20px);
                    }
                    &__list {
                        font-size: vwm(14px);
                        margin-left: vwm(20px);
                    }
                }
            }
            &__nav {
                margin-top: vwm(40px);
                margin-left: vwm(-15px);
                &__list {
                    font-size: vwm(16px);
                    margin: 0 vwm(15px) vwm(5px);
                }
            }
        }
        &__logo {
            width: vwm(170px);
        }
        &__socialmedia {
            margin-top: vwm(50px);
            &__list {
                width: vwm(32px);
            }
        }
        &__under {
            padding: vwm(40px) 0 vwm(60px);
            &__nav__block {
                display: flex;
                width: calc(100% - vwm(300px));
            }
            &__nav {
                &:nth-child(2) {
                    margin-left: vwm(30px);
                }
                &__list {
                    &:nth-child(n + 2) {
                        margin-left: vwm(30px);
                    }
                }
                &__link {
                    font-size: vwm(13px);
                }
            }
            .l-footer__copy {
                width: vwm(350px);
                font-size: vwm(13px);
            }
        }
    }
    @include sp {
        &__inner {
            padding: 0 15px;
        }
        &__upper {
            padding: 50px 0 0;
            display: block;
            &__left {
                width: 100%;
                margin-right: 0;
            }
            &__right {
                width: 100%;
            }
            &__products {
                margin-top: 50px;
                display: block;
                &__list {
                    font-size: 1.6rem;
                    display: block;
                    border-top: 1px solid #dddddd;
                    &:nth-child(n + 2) {
                        margin-top: 0;
                    }
                }
                &__link {
                    display: block;
                    padding: 20px 10px;
                    position: relative;
                    &::after {
                        content: "";
                        position: absolute;
                        width: 7px;
                        height: 12px;
                        top: 50%;
                        right: 20px;
                        transform: translate(0, -50%);
                        background: url(/assets/img/common/icon_arrow_right.png) center/contain no-repeat;
                    }
                }
                .js-footer-dropdown-target {
                    height: 0;
                    overflow: hidden;
                    transition: $transition;
                }
                &__inner {
                    display: block;
                    margin-top: -10px;
                    &::before {
                        display: none;
                    }
                    &__list {
                        font-size: 1.4rem;
                        font-weight: normal;
                        margin-left: 20px;
                        &:last-child {
                            padding-bottom: 15px;
                        } 
                    }
                    &__link {
                        padding: 10px 0 10px 15px;
                        display: block;
                        position: relative;
                        &::before {
                            content: "";
                            position: absolute;
                            width: 6px;
                            height: 1px;
                            background: #999999;
                            top: 50%;
                            left: 0px;
                            transform: translate(0, -50%);
                        }
                    }
                }
            }
            &__nav {
                margin-top: 0;
                display: block;
                margin-left: 0;
                &__list {
                    margin: 0;
                    border-top: 1px solid #dddddd;
                    &:last-child {
                        border-bottom: 1px solid #dddddd;
                    }
                }
                &__link {
                    display: block;
                    padding: 20px 10px;
                    position: relative;
                    &::after {
                        content: "";
                        position: absolute;
                        width: 7px;
                        height: 12px;
                        top: 50%;
                        right: 20px;
                        transform: translate(0, -50%);
                        background: url(/assets/img/common/icon_arrow_right.png) center/contain no-repeat;
                    }
                }
            }
        }
        &__logo {
            width: 150px;
        }
        &__socialmedia {
            width: 240px;
            margin: 40px auto 0;
            &__list {
                width: 35px;
            }
        }
        &__under {
            border-top: none;
            padding:  35px 0 50px;
            display: block;
            &__nav__block {
                flex-wrap: wrap;
                width: 100%;
            }
            &__nav {
                flex-wrap: wrap;
                justify-content: center;
                width: 100%;
                &:nth-child(n + 2) {
                    margin-left: 0;
                    margin-top: 10px;
                }
                &__list {
                    &:nth-child(n + 2) {
                        margin-left: 0;
                    }
                    &:nth-child(2n) {
                        margin-left: 20px;
                    }
                }
            }
            .l-footer__copy {
                width: 100%;
                margin-top: 45px;
                font-size: 1.2rem;
                color: #888888;
                text-align: center;
            }
        }
    }
}