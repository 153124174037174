@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

.l-main {
  // もっと見るボタン
  .js-more-btn {
    transition: $transition;
    &.active {
      opacity: 0;
      height: 0;
      visibility: hidden;
      margin: 0 !important;
      padding: 0 !important;
    }
    &-target {
      opacity: 0;
      height: 0;
      visibility: hidden;
      transition: $transition;
      &.active {
        opacity: 1;
        height: auto;
        visibility: visible;
      }
    }
  }
  .js-one_more-btn {
    transition: $transition;
    &.active {
      opacity: 0;
      height: 0;
      visibility: hidden;
      margin: 0 !important;
      padding: 0 !important;
    }
    &-target {
      > * {
        display: none;
        opacity: 0;
      }
    }
  }
}

// モーダル表示
.js-modal {
  display: block;
  cursor: pointer;
  transition: 0.4s;
}
.js-modal:hover {
  opacity: 0.55;
}

.modal {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  width: 90%;
  max-width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8);
  opacity: 0;
  overflow-x: auto;
  overflow-y: hidden;
  margin: 0 auto;
  white-space: nowrap;
  box-sizing: border-box;
}
@-moz-document url-prefix() {
  .modal {
    justify-content: space-between;
  }
}

.modal-content {
  display: inline-block;
  text-align: center;
  img {
    width: auto;
    max-width: none;
    height: 100%;
    max-height: 580px;
  }
  .m-image__image {
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .m-image--scale {
    padding: 5px;
    text-align: right;
    background: #fff;
  }
}

.modal {
  .js-modal__item {
    .m-image-text__caption {
      color: #fff;
    }
  }
  .m-tac {
    text-align: center;
  }
  .m-tar {
    text-align: right;
  }
  .m-tal {
    text-align: left;
  }
  .m-tac-pc {
    @include pc {
      text-align: center;
    }
  }
  @include sp {
    .m-tal-sp {
      text-align: left;
    }
  }
}
.modal:has(img):has(.m-table) {
  .m-image {
    display: flex;
    align-items: center;
  }
  .m-image__item + .m-image__item {
    margin-left: 30px;
  }
}
.js-modal {
  .js-modal-only {
    display: none;
  }
}
.modal {
  .js-modal-only {
    display: block;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  z-index: 999;

  .m-table {
    &__content {
      width: 100%;
      border-collapse: collapse;
    }
    &__head__item,
    &__data {
      font-size: 1.5rem;
      line-height: 1.6;
      padding: 20px 15px;
      border: 1px solid #cccccc;
    }
    &__head {
      .m-table__head__item {
        background: #dcebf7;
        font-weight: 500;
      }
    }
    &__body {
      .m-table__head__item {
        background: #eef4f9;
        font-weight: 500;
      }
      .m-table__data {
        background: #fff;
        .m-text--normal {
          font-size: 1.5rem;
          line-height: 1.6;
        }
        .m-text--normal + .m-list--dot {
          margin-top: 5px;
        }
        .m-list--dot {
          &__item {
            &:nth-child(n + 2) {
              margin-top: 5px;
            }
          }
        }
      }
    }
    &__colgroup {
      col {
        @for $i from 1 through 99 {
          &.m-width#{$i}per {
            width: $i * 1%;
          }
        }
      }
    }
    @include sp {
      &__body {
        .m-table__data {
          .m-text--normal { 
            font-size: 1.4rem;
          }
        }
      }
      &__head__item,
      &__data {
        font-size: 1.4rem;
        padding: 15px 10px;
        .m-text-link {
          &__link {
            padding-left: 16px;
            font-size: 1.4rem;
            &::before {
              width: 6px;
              height: 9px;
              top: 7px;
            }
          }
        }
      }
    }
    &__colgroup {
      col {
        @include sp {
          @for $i from 1 through 99 {
            &.m-width#{$i}per-sp {
              width: $i * 1%;
            }
          }
        }
      }
    }
    &--scroll {
      overflow-x: auto;
      @include sp {
        .m-table__content {
          min-width: 600px;
          &.m-table-780px-sp {
            min-width: 780px;
          }
          &.m-table-1200px-sp {
            min-width: 1200px;
          }
        }
      }
    }
  }
}

.modal-overlay.show {
  opacity: 1;
}
.modal.show {
	opacity: 1;
}

.modal-closeArea {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 201;
}

.l-main {
  .m-image-text__image {
    &.js-modal {
      background-color: transparent;
    }
  }
}



// 目次

.l-main {
  .m-contents-text-ac {
    background: #f4f4f8;
    border-top: none;
    &__menu {
      position: relative;
      align-items: center;
      background: #f4f4f8;
      border-bottom: none;
      cursor: pointer;
      z-index: 10;
      button {
        padding: 25px 20px 25px 20px;
        transition: $transition;
        hr {
          color: #e5e5e5;
          margin-top: 15px;
          border-bottom: solid 1px #e5e5e5;
        }
      }
      &__title {
        position: relative;
        width: 100%;
        font-size: 3.2rem;
        line-height: 1.4;
        font-weight: 500;
        padding: 25px 60px 25px 20px;
        cursor: pointer;
        transition: all .2s linear;
        &:hover {
          color: #002d78;
          background: none;
          opacity: .8;
        }
        &:before {
          content: none;
        }
        &:after {
          content: none;
        }
      }
      span {
        font-size: 1.4rem;
        margin-left: 20px;
        padding-top: 8px;
      }
    }
    &__item {
      margin-right: 25px;
      margin-bottom: 15px;
      &__txt {
        display: inline-block;
      }
      li {
        position: relative;
        font-size: 1.6rem;
        line-height: 1.4;
        transition: $transition;
        align-items: center;
        margin-bottom: 0.8rem;
        a {
          cursor: pointer;
          &:hover {
            color: #002d78;
          }
        }
      }
      > li {
        > a {
          font-weight: bold;
        }
      }
      &__second {
        padding: 1.6rem 0 0.2rem 3.4rem;
        ol {
          padding: 1.6rem 0 0.2rem 3.4rem;
        }
      }
    }
    .js-contents-content {
      padding: 0 25px 25px 35px;
      transition: $transition;
      @include sp {
        padding: 0 20px 15px 20px;
      }
    }
    @include sp {
      padding: 0 15px 0;
      &__wrap {
        flex-direction: column;
        display: none;
        padding-bottom: 20px;
      }
      &__item {
        width: 100%;
        margin-right: 0;
        margin-bottom: 0;
        &__link {
          padding: 7px 0 7px 32px;
          font-size: 1.5rem;
          display: flex;
        }
      }
    }
  }
}
.js-contents-target {
  display: none; /* 初期状態では非表示 */
}

.js-contents-target.active {
  display: block; /* クリックされたら表示 */
}