@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

.l-main {
    .m-h2--gradient {
        font-size: 3.2rem;
        line-height: 1.4;
        &__inner {
            background: $c-accent;
            background: -webkit-linear-gradient( 0deg, rgb(0,91,136) 0%, rgb(1,117,139) 50%, rgb(1,129,140) 100%);
            background-clip: text;
            color: transparent;
        }
        @include sp {
            font-size: 2.2rem;
        }
    }
    .m-h3--gradient {
        font-size: 2.6rem;
        line-height: 1.4;
        &__inner {
            background: $c-accent;
            background: -webkit-linear-gradient( 0deg, rgb(0,91,136) 0%, rgb(1,117,139) 50%, rgb(1,129,140) 100%);
            background-clip: text;
            color: transparent;
        }
        @include sp {
            font-size: 2rem;
        }
    }
    .m-sec {
        &__ttl {
            line-height: 1.2;
            &__color {
                background: $c-accent;
                background: -webkit-linear-gradient( 45deg, rgb(1,60,136) 0%, rgb(0,93,136) 50%, rgb(1,129,140) 100%);
                background-clip: text;
                color: transparent;
                font-feature-settings : inherit;
                font-size: 3.8rem;
                font-family: Oswald-Regular;
            }
            &__sub {
                margin-top: 10px;
                display: block;
                background-clip: none;
                color: $c-base;
                font-size: 1.4rem;
            }
            &__thin {
                font-family: Oswald-Light;
            }
        }
    }
}
