@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// 用語集TOP
.p-words {
    &__index {
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        &__item {
            width: calc((100% - 3.3% * 3) / 4);
            margin-right: 3.3%; //40px
            position: relative;
            &:last-child {
                margin-right: 0;
            }
            .img {
                overflow: hidden;
                img {
                    transition: $transition;
                }
            }
            .ttl {
                font-size: 2rem;
                color: #fff;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 0 20px 25px;
                z-index: 1;
                &--sub {
                    display: block;
                    font-size: 1.7rem;
                }
            }
            .link {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 5;
            }
            @include pc {
                &:hover {
                    .img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
        @include sp {
            margin-top: 25px;
            justify-content: flex-start;
            &__item {
                width: calc((100% - 15px) / 2);
                margin-right: 15px;
                &:nth-child(2n) {
                    margin-right: 0;
                }
                &:nth-child(n + 3) {
                    margin-top: 15px;
                }
                .ttl {
                    font-size: 1.6rem;
                    padding: 0 10px 20px;
                    &--sub {
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
    .m-news {
        margin-top: 20px;
        @include sp {
            margin-top: 0;
        }
    }
}

// 用語集下層
.p-words-detail {
    .width-u-100, .width-lg-100, .width-md-100 {
        max-width: 100%;
        width: auto;
    }
    .width-u-50 {
        max-width: 100%;
        width: 50%;
        @include sp {
            width: 100%;
        }
    }
    &__info {
        margin-top: 40px;
        &__ttl {
            font-size: 1.8rem;
            font-weight: bold;
        }
        &__txt {
            margin-top: 10px;
            font-size: 1.6rem;
            line-height: 2;
        }
        @include sp {
            margin-top: 25px;
        }
    }
    &__words {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 25px;
        .tag {
            font-size: 1.2rem;
            font-weight: 500;
            background: #eeeef4;
            padding: 5px 10px;
            height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
            border-radius: 4px;
        }
        &__list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .m-text-link {
                margin-right: 15px;
                + .m-text-link {
                    margin-top: 0;
                }
            }
        }
        @include sp {
            &__list {
                margin-top: 10px;
                width: 100%;
            }
        }
    }
    .m-list--annotation {
        margin-top: 20px;
    }
    .search > {
        .m-list--annotation {
            max-width: 600px;
            margin: 10px auto 0;
        }
    }


    // その他の用語集リンク
    &__linkbox {
        border-top: 1px solid #cccccc;
        padding: 60px 0 100px;
        @include sp {
            padding: 40px 0 90px;
        }
    }
    &__links {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        &__item {
            width: calc((100% - 3.3% * 2) / 3);
            margin-right: 3.3%; //40px
            position: relative;
            &:nth-child(3n) {
                margin-right: 0;
            }
            .img {
                overflow: hidden;
                img {
                    transition: $transition;
                }
            }
            .ttl {
                font-size: 2rem;
                color: #fff;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 0 20px 25px;
                z-index: 1;
                &--sub {
                    display: block;
                    font-size: 1.7rem;
                }
            }
            .link {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 5;
            }
            @include pc {
                &:hover {
                    .img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
        @include sp {
            margin-top: 25px;
            &__item {
                width: 100%;
                margin-right: 0;
                &:nth-child(3n) {
                    margin-right: 0;
                }
                &:nth-child(n + 2) {
                    margin-top: 15px;
                }
                .ttl {
                    font-size: 1.6rem;
                    padding: 0 10px 20px;
                    &--sub {
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        &--col3 {
            .p-words-detail__list__item {
                width: calc((100% - 80px) / 3);
                .mean {
                    display: none;
                }
            }
        }
        &--mean--col3 {
            .p-words-detail__list__item {
                width: calc((100% - 80px) / 3);
            }
        }
        &--col2 {
            .p-words-detail__list__item {
                width: calc((100% - 40px) / 2);
                .mean {
                    display: none;
                }
            }
        }
        &--mean--col2 {
            .p-words-detail__list__item {
                width: calc((100% - 40px) / 2);
            }
        }
        &--emverb--col2 {
            .p-words-detail__list__item {
                width: calc((100% - 40px) / 2);
            }
        }
        &__item {
            position: relative;
            padding-left: 20px;
            &.-hide {
                display: none;
                opacity: 0;
            }
            &::before {
                content: "";
                position: absolute;
                width: 6px;
                height: 9px;
                top: 9px;
                left: 0px;
                background: url("/assets/img/common/icon_arrow_right.png") center/contain no-repeat;
            }
            @include pc {
                &:hover {
                    .title {
                        color: $c-accent;
                        &__inner {
                          &::before {
                            transform: scaleX(1);
                          }
                        }
                    }
                }
            }
            .title {
                font-size: 1.8rem;
                font-weight: 500;
                &__inner {
                    @include link-underline {
                        &::before {
                            background: $c-accent;
                        }
                    }
                }
            }
            .mean {
                display: flex;
                flex-wrap: wrap;
                li {
                    margin-top: 5px;
                    margin-right: 15px;
                    font-size: 1.4rem;
                    color: $c-sub;
                }
            }
            .relative-word {
                margin-top: 15px;
                display: flex;
                flex-wrap: wrap;
                &.relative-word--synonyms {
                    .relative-word__title {
                        color: #fff;
                        background: $c-sub;
                    }
                }
                &.relative-word--antonyms {
                    .relative-word__title {
                        color: $c-sub;
                        border: 1px solid $c-sub;
                    }
                }
                &__title {
                    font-size: 1.3rem;
                    width: 50px;
                    margin-right: 15px;
                    align-self: flex-start;
                    text-align: center;
                    margin-top: 5px;
                }
                &__list {
                    display: flex;
                    flex-wrap: wrap;
                    width: calc(100% - 65px);
                    li {
                        font-size: 1.4rem;
                        color: $c-sub;
                        margin-right: 15px;
                        line-height: 2;
                    }
                }
            }
            .link {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
          }
          @include sp {
            gap: 20px 10px;
            &--col3 {
                .p-words-detail__list__item {
                    width: calc((100% - 10px) / 2);
                }
            }
            &--emverb--col2 {
                gap: 25px 0;
                .p-words-detail__list__item {
                    width: 100%;
                }
            }
            &__item {
                .title {
                    font-size: 1.5rem;
                }
                .mean {
                    li {
                        font-size: 1.2rem;
                    }
                }
                .relative-word {
                    margin-top: 10px;
                    &__title {
                        font-size: 1.2rem;
                        width: 48px;
                        margin-right: 10px;
                        margin-top: 3px;
                    }
                    &__list {
                        width: calc(100% - 58px);
                        li {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
    }
}


// 用語集下層の検索結果画面
.js-word-search-target {
    visibility: hidden;
    height: 0;
}
.p-words-detail_search_btn {
    font-size: 1.6rem;
    margin-top: 30px;

    @include sp {
        margin-top: 20px;
    }
    .p-words-detail_search_btn__inner {
        transition: all .2s linear;
        position: relative;
        padding-right: 30px;
        &::after {
            position: absolute;
            content: "";
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
            width: 14px;
            height: 14px;
            background: url("/assets/img/common/icon_plus.png") center/contain no-repeat;
        }
        &:hover {
            color: #002d78;
        }
    }
}
.p-words-detail_search {
    .gsc-adBlock {
        display: none;
    }
    .gsc-control-cse .gs-spelling {
        padding: 0;
    }
    .gsc-results {
        width: 100%;
        padding-bottom: 0;
    }
    .gcsc-more-maybe-branding-root {
        display: none;
    }
    .gsc-above-wrapper-area {
        display: none;
    }
    .gs-webResult div.gs-visibleUrl-breadcrumb {
        display: none;
    }
    .gsc-control-cse .gsc-table-result {
        display: none;
    }
    .gcsc-find-more-on-google {
        display: none;
    }
    .gsc-control-cse {
        padding: 0;
        margin-top: 20px;
    }
    .gsc-thumbnail-inside {
        padding-left: 0;
    }
    .gcsc-find-more-on-google-root {
        display: none;
    }
    .gs-result .gs-title a {
        font-family: "NotoSansJP","Yu Gothic","Yu Gothic Medium","游ゴシック Medium",YuGothic,"游ゴシック体","ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        color: #002d78;
        transition: all .2s linear;
        font-size: 1.6rem;

        @include sp {
            font-size: 1.4rem;
        }
    }
     // ページネーション調整用
     .gsc-results-wrapper-visible {
        padding-top: 150px;
        margin-top: -150px;
    }
    @include pc {
        .gsc-results .gsc-cursor {
            display: flex;
        }
        .gsc-results .gsc-cursor-box .gsc-cursor-page {
            text-decoration: none;
            font-size: 1.6rem;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            background: #f4f4f8;
            transition: all .2s linear;
            font-family: Oswald-Medium;
            &:hover {
                text-decoration: none;
                color: #fff;
                background: #606e85;
            }
        }
        .gsc-results .gsc-cursor-box .gsc-cursor-current-page {
            color: #fff;
            background: #606e85;
            pointer-events: none;
        }
    }
}
