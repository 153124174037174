@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// mrc_webformのCSSについては以下ページで使用
// JEOL製品お問い合わせ
// 「BioMajesty™で始める試薬管理」のご紹介 (試薬・帳票管理運用 アンケート）
.p-contact-products {
    .mrc_webform {
        overflow: auto;
    }
    .mrc_webform_postmsg {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    #mrc_webform .mrc_webform_row .mrc_webform_a textarea, #mrc_download .mrc_webform_row .mrc_webform_a textarea {
        width: 100% !important;
    }
    @include sp {
        #mrc_webform .mrc_webform_row .mrc_webform_a select, #mrc_download .mrc_webform_row .mrc_webform_a select {
            width: 100% !important;
        }
        #mrc_webform .mrc_webform_row .mrc_webform_a textarea, #mrc_download .mrc_webform_row .mrc_webform_a textarea {
            width: 100% !important;
        }
        .m-col2__item:nth-child(n+2) {
            margin-top: 20px;
        }
        #mrc_webform .mrc_webform_row .mrc_webform_a input[type='text'], #mrc_download .mrc_webform_row .mrc_webform_a input[type='text'], #mrc_webform .mrc_webform_row .mrc_webform_a input[type='date'], #mrc_download .mrc_webform_row .mrc_webform_a input[type='date'], #mrc_webform .mrc_webform_row .mrc_webform_a input[type='number'], #mrc_download .mrc_webform_row .mrc_webform_a input[type='number'], #mrc_webform .mrc_webform_row .mrc_webform_a input[type='email'], #mrc_download .mrc_webform_row .mrc_webform_a input[type='email'], #mrc_webform .mrc_webform_row .mrc_webform_a input[type='tel'], #mrc_download .mrc_webform_row .mrc_webform_a input[type='tel'] {
            width: 100% !important;
        }
    }
}