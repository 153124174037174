@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

.p-medical {
    .m-checklist__checkflex {
        &--center {
            .m-checklist__checkflex__item {
                width: 535px;
            }
        }
    }
}