@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// オペレーティングリース及びレンタルのご紹介
.p-rental {
    .js-movie-modal-open {
        .m-image-text__image {
            background: #000;
            cursor: pointer;
            img {
                transition: $transition;
            }
        }
        @include pc {
            &:hover {
                .m-image-text__image img {
                    opacity: 0.6;
                }       
            }
        }
    }
    .m-modal {
        &__contents {
            max-width: 900px;
            &__inner {
                padding: 0;
            }
        }
    }
    &__border {
        position: relative;
        &::before {
            position: absolute;
            content: '';
            left: 50%;
            top: -35px;
            width: 1px;
            height: 70px;
            background: -webkit-linear-gradient(00deg, rgb(1, 60, 136) 0%, rgb(0, 93, 136) 50%, rgb(1, 129, 140) 100%);
        }
        @include sp {
            &::before {
                top: -25px;
                height: 50px;
            }
        }
    }
    &__card {
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
        &__item {
            background: #fff;
            padding: 40px 45px;
            width: calc((100% - 50px) / 2);
            margin-right: 50px;
            position: relative;
            &:nth-child(2n) {
                margin-right: 0;
            }
            &:nth-child(n + 3) {
                margin-top: 50px;
            }
        }
        &__num {
            position: absolute;
            font-family: Oswald-Light;
            font-size: 4.2rem;
            top: -35px;
            &__inner {
                display: inline-block;
                background: #002d78;
                background: -webkit-linear-gradient(0deg, rgb(1, 60, 136) 0%, rgb(0, 93, 136) 50%, rgb(1, 129, 140) 100%);
                -webkit-background-clip: text;
                background-clip: text;
                color: rgba(0,0,0,0);
            }
        }
        .m-h4 {
            margin-top: 0;
            + * {
                margin-top: 15px;
            }
        }
        @include sp {
            margin-top: 40px;
            &__item {
                padding: 30px 20px;
                width: 100%;
                margin-right: 0;
                &:nth-child(n + 2) {
                    margin-top: 30px;
                }
                &:nth-child(n + 3) {
                    margin-top: 30px;
                }
            }
            &__num {
                font-size: 2.8rem;
                top: -22px;
            }
        }
    }
    .m-table {
        padding-top: 35px;
        &__head {
            &__item {
                font-size: 1.8rem;
                text-align: center;
                vertical-align: middle;
            }
            .m-table__head__item {
                color: #fff;
                &:first-child {
                    background: #fff;
                    border: none;
                }
            }
        }
        &__data {
            text-align: center;
            vertical-align: middle;
        }
        .p-rental__bg {
            &--green {
                background: #47b6a2;
            }
            &--gray {
                background: #7e898f;
            }
            &--blue {
                background-image: -webkit-linear-gradient( -180deg, rgb(87,197,207) 0%, rgb(73,169,214) 24%, rgb(58,140,220) 50%, rgb(49,118,206) 79%, rgb(39,96,191) 100%);
            }
            &--paleblue {
                background: #eff8ff;
            }
        }
        .p-rental__text {
            &__gradient {
                font-weight: 500;
                font-size: 2.2rem;
                &__inner {
                    background: #002d78;
                    background: -webkit-linear-gradient(45deg, rgb(1, 60, 136) 0%, rgb(0, 93, 136) 50%, rgb(1, 129, 140) 100%);
                    -webkit-background-clip: text;
                    background-clip: text;
                    color: rgba(0,0,0,0);
                }
            }
            &__small {
                font-size: 1.8rem;
            }
        }
        .p-rental__point {
            position: relative;
            &::after {
                position: absolute;
                content: "お徳！";
                top: -30px;
                right: 20%;
                font-size: 1.7rem;
                font-weight: 500;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                background-image: -webkit-linear-gradient( 45deg, rgb(1,60,136) 0%, rgb(0,93,136) 50%, rgb(1,129,140) 100%);
                width: 60px;
                height: 60px;
                border-radius: 50%;
                box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.08);
            }
        }
        @include pcmin1200 {
            .p-rental__point {
                &::after {
                    right: 5%;
                }
            }
        }
        @include sp {
            padding-top: 35px;
            &__head {
                &__item {
                    font-size: 1.6rem;
                }
            }
            .p-rental__text {
                &__gradient {
                    font-size: 1.8rem;
                }
                &__small {
                    font-size: 1.4rem;
                }
            }
            .p-rental__point {
                &::after {
                    top: -20px;
                    right: 5%;
                    font-size: 1.4rem;
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }
    &__image1000 {
        max-width: 1000px;
        margin: 50px auto 0;
        .m-list--annotation {
            margin-top: 20px;
            counter-reset: num 1;
            &__item {
                padding-left: 30px;
                counter-increment: num 1;
                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: inherit;
                    height: inherit;
                    content: '※' counter(num) ;
                }
            }
        }
        @include sp {
            margin: 30px auto 0;    
        }
    }
    .l-lower__bg {
        &.m-mt60px {
            margin-top: 60px;
        }
        @include sp {
            &.m-mt40px-sp {
                margin-top: 40px;
            }
        }
    }
}