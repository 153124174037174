@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

.p-feature_eo {
    .m-imagetext {
        &__img {
            background: #f7f7f7;
        }
        + .m-imagetext {
            padding-top: 0px;
            border-top: none;
        }
    }
}