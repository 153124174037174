@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

/* パーツリストモジュール
***********************/
.l-main, .content_data {
  // テキスト
  .m-text--biggest {
    font-size: 2.4rem;
    line-height: 2;
    + .m-text--biggest,
    + .m-text--bigger,
    + .m-text--big,
    + .m-text--normal {
      margin-top: 20px;
    }
    + .m-text--small,
    + .m-text--smallest {
      margin-top: 10px;
    }
    @include sp {
      font-size: 2rem;
    }
  }
  .m-text--bigger {
    font-size: 2.1rem;
    line-height: 2;
    + .m-text--biggest,
    + .m-text--bigger,
    + .m-text--big,
    + .m-text--normal {
      margin-top: 20px;
    }
    + .m-text--small,
    + .m-text--smallest {
      margin-top: 10px;
    }
    @include sp {
      font-size: 1.8rem;
    }
  }
  .m-text--big {
    font-size: 1.8rem;
    line-height: 2;
    + .m-text--biggest,
    + .m-text--bigger,
    + .m-text--big,
    + .m-text--normal {
      margin-top: 20px;
    }
    + .m-text--small,
    + .m-text--smallest {
      margin-top: 10px;
    }
    @include sp {
      font-size: 1.6rem;
    }
  }
  .m-text--normal {
    font-size: 1.6rem;
    line-height: 2;
    + .m-text--biggest,
    + .m-text--bigger,
    + .m-text--big,
    + .m-text--normal {
      margin-top: 20px;
    }
    + .m-text--small,
    + .m-text--smallest {
      margin-top: 10px;
    }
    &.-hide {
      display: none;
    }
  }
  .m-text--small {
    font-size: 1.4rem;
    line-height: 2;
    + .m-text--biggest,
    + .m-text--bigger,
    + .m-text--big {
      margin-top: 20px;
    }
    + .m-text--small,
    + .m-text--smallest {
      margin-top: 10px;
    }
  }
  .m-text--smallest {
    font-size: 1.2rem;
    line-height: 2;
    + .m-text--biggest,
    + .m-text--bigger,
    + .m-text--big,
    + .m-text--normal {
      margin-top: 20px;
    }
    + .m-text--small,
    + .m-text--smallest {
      margin-top: 10px;
    }
  }

  .m-imageintext {
    vertical-align: middle;
    width: inherit;
  }

  .m-tac {
    text-align: center;
  }
  .m-tar {
    text-align: right;
  }
  .m-tal {
    text-align: left;
  }
  .m-tac-pc {
    @include pc {
      text-align: center;
    }
  }
  @include sp {
    .m-tal-sp {
      text-align: left;
    }
  }

  .m-midium {
    font-weight: 500;
  }
  .m-bold {
    font-weight: bold;
  }
  .m-blue {
    color: $c-accent;
  }
   .m-green {
    color: #2A9D97;
  }
  .m-red {
    color: #e60e00;
  }
  .m-color-sub {
    color: $c-sub;
  }

  .m-linethrough {
    text-decoration: line-through;
  }

  .m-sup {
    font-size: 1rem;
    vertical-align: super;
  }
  .m-sub {
    font-size: 1rem;
    vertical-align: baseline;
  }

  .m-link {
    color: $c-accent;
    border-bottom: 1px solid $c-accent;
    transition: $transition;
    &:hover {
      border-color: transparent;
    }
    &.newwindow {
      margin-right: 25px;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        bottom: 5px;
        margin-left: 5px;
        width: 13px;
        height: 12px;
        background: url(/assets/img/common/icon_newwindow.png) center/contain
          no-repeat;
      }
    }
  }

  .m-copy--large {
    font-size: 3rem;
    color: $c-accent;
    font-weight: 500;
    line-height: 1.6;
    + * {
      margin-top: 30px;
    }
    + .m-h2 {
      margin-top: 100px;
    }
    @include sp {
      font-size: 2.2rem;
      + * {
        margin-top: 20px;
      }
      + .m-h2 {
        margin-top: 60px;
      }
    }
  }
  .m-copy--midium {
    font-size: 2.4rem;
    color: $c-accent;
    font-weight: 500;
    line-height: 1.6;
    + * {
      margin-top: 30px;
    }
    @include sp {
      font-size: 1.8rem;
      + * {
        margin-top: 20px;
      }
    }
  }
  .m-copy--normal {
    font-size: 2.2rem;
    color: $c-accent;
    font-weight: 500;
    line-height: 1.6;
    + * {
      margin-top: 30px;
    }
    @include sp {
      font-size: 1.8rem;
      + * {
        margin-top: 20px;
      }
    }
  }
  .m-copy--small {
    font-size: 1.5rem;
    color: $c-accent;
    line-height: 1.5;
    + * {
      margin-top: 20px;
    }
  }

  // リスト
  .m-list--dot {
    margin-top: 25px;
    &__item {
      position: relative;
      font-size: 1.6rem;
      line-height: 1.6;
      padding-left: 20px;
      > p {
        font-size: 1.6rem;
        line-height: 1.6;
      }
      &::before {
        position: absolute;
        content: "";
        top: 10px;
        left: 5px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: $c-sub;
      }
      &:nth-child(n + 2) {
        margin-top: 15px;
      }
      &__inner {
        &__item {
          padding-left: 20px;
          margin-top: 10px;
          font-size: 1.5rem;
          color: $c-sub;
          position: relative;
          > p {
            font-size: 1.5rem;
            color: $c-sub;
          }
          &::before {
            position: absolute;
            content: "";
            top: 10px;
            left: 5px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: $c-sub;
          }
        }
      }
      // a {
      //     text-decoration: underline;
      //     &:hover {
      //         text-decoration: none;
      //     }
      // }
    }
    &--blue {
      .m-list--dot {
        &__item {
          &::before {
            top: 15px;
            color: $c-accent;
          }
        }
      }
    }
    @include sp {
      margin-top: 15px;
    }
  }
  .m-list--num {
    margin-top: 25px;
    &__item {
      position: relative;
      font-size: 1.6rem;
      line-height: 1.6;
      padding-left: 20px;
      counter-increment: list-num;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: counter(list-num) ".";
        width: initial;
        height: initial;
        border: initial;
        background: initial;
      }
      &:nth-child(n + 2) {
        margin-top: 15px;
      }
      > p {
        font-size: 1.6rem;
        line-height: 1.6;
      }
      &__inner {
        &__item {
          padding-left: 20px;
          margin-top: 10px;
          font-size: 1.5rem;
          color: $c-sub;
          counter-increment: list-inner-num;
          position: relative;
          &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: counter(list-inner-num) ".";
            width: initial;
            height: initial;
            border: initial;
            background: initial;
          }
        }
      }
    }
    + .m-text--normal {
      margin-top: 25px;
    }
    @include sp {
      margin-top: 15px;
    }
  }

  .m-list--num--2digits {
    margin-top: 25px;
    &__item {
      position: relative;
      font-size: 1.6rem;
      line-height: 1.6;
      padding-left: 28px;
      counter-increment: list-num;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: counter(list-num) ".";
        width: initial;
        height: initial;
        border: initial;
        background: initial;
      }
      &:nth-child(n + 2) {
        margin-top: 15px;
      }
      > p {
        font-size: 1.6rem;
        line-height: 1.6;
      }
      &__inner {
        &__item {
          padding-left: 28px;
          margin-top: 10px;
          font-size: 1.5rem;
          color: $c-sub;
          counter-increment: list-inner-num;
          position: relative;
          &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: counter(list-inner-num) ".";
            width: initial;
            height: initial;
            border: initial;
            background: initial;
          }
        }
        .m-list--num--2digits__item__inner {
          > .m-list--num--2digits__item__inner__item {
            padding-left: 28px;
            margin-top: 10px;
            font-size: 1.5rem;
            color: $c-sub;
            counter-increment: list-more-inner-num;
            position: relative;
            &::before {
              position: absolute;
              top: 0;
              left: 0;
              content: counter(list-more-inner-num) ".";
              width: initial;
              height: initial;
              border: initial;
              background: initial;
            }
          }
        }
      }
    }
    + .m-text--normal {
      margin-top: 25px;
    }
    @include sp {
      margin-top: 15px;
    }
  }

  .m-list--good {
    &__item {
      position: relative;
      font-size: 1.6rem;
      line-height: 1.6;
      padding-left: 20px;
      &::before {
        position: absolute;
        content: "";
        top: 3px;
        left: -3px;
        width: 20px;
        height: 20px;
        background: url(/assets/img/common/icon_good.jpg) center/contain no-repeat;
      }
      &:nth-child(n + 2) {
        margin-top: 15px;
      }
    }
  }

  .m-list--annotation {
    margin-top: 25px;
    &__item {
      position: relative;
      font-size: 1.4rem;
      color: #666;
      line-height: 1.4;
      padding-left: 20px;
      > p {
        font-size: 1.4rem;
        color: #666;
        line-height: 1.4;
      }
      &:nth-child(n + 2) {
        margin-top: 8px;
      }
      &.m-red {
        color: $c-red;
        > p {
          color: $c-red;  
        }
      }
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "※";
        width: 0;
        height: 0;
        border-radius: inherit;
        background: none;
      }
    }
    @include sp {
      margin-top: 15px;
    }
  }

  .m-list--annotation-num {
    counter-reset: list-annotation-num;
    &__item {
      position: relative;
      font-size: 1.4rem;
      color: $c-sub;
      line-height: 1.6;
      padding-left: 30px;
      counter-increment: list-annotation-num;
      > p {
        font-size: 1.4rem;
        color: $c-sub;
        line-height: 1.6;
      }
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "※" counter(list-annotation-num);
        width: initial;
        height: initial;
        border: initial;
        background: initial;
      }
      &:nth-child(n + 2) {
        margin-top: 8px;
      }
      &__inner {
        counter-reset: list-annotation-list-inner-num;
        &__item {
          margin-top: 10px;
          counter-increment: list-annotation-list-inner-num;
          padding-left: 20px;
          position: relative;
          &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: "*" counter(list-annotation-list-inner-num);
            width: initial;
            height: initial;
            border: initial;
            background: initial;
          }
        }
      }
    }
  }

  .m-list--alpha {
    counter-reset: list-alpha;
    &__item {
      position: relative;
      font-size: 1.6rem;
      line-height: 1.6;
      padding-left: 20px;
      counter-increment: list-alpha;
      > p {
        font-size: 1.6rem;
        line-height: 1.6;
      }
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: counter(list-alpha, lower-alpha) ".";
        width: initial;
        height: initial;
        border: initial;
        background: initial;
      }
      &:nth-child(n + 2) {
        margin-top: 15px;
      }
      &__inner {
        counter-reset: list-inner-alpha;
        &__item {
          padding-left: 20px;
          margin-top: 10px;
          font-size: 1.5rem;
          color: $c-sub;
          counter-increment: list-inner-alpha;
          position: relative;
          > p {
            font-size: 1.5rem;
            color: $c-sub;
          }
          &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: counter(list-inner-alpha, lower-alpha) ".";
            width: initial;
            height: initial;
            border: initial;
            background: initial;
          }
        }
      }
    }
    + .m-text--normal {
      margin-top: 25px;
    }
  }

  .m-imagetext {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    margin-top: 50px;
    padding: 50px 0 20px;
    border-top: 1px solid #eee;
    &__link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    span.pdf {
      font-size: 1.6rem;
      color: $c-sub;
      font-weight: normal;
      position: relative;
      display: inline-block;
      margin-left: 20px;
      padding-left: 23px;
      &::before {
        content: "";
        position: absolute;
        width: 18px;
        height: 17px;
        top: 1px;
        left: 0;
        background: url(/assets/img/common/icon_pdf.png) center/contain
          no-repeat;
      }
    }
    &__img {
      width: 270px;
      max-height: 270px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      overflow: hidden;
      img {
        transition: $transition;
        max-height: 270px;
        object-fit: contain;
      }
      // align-items: center;
      // background: #f7f7f7;
    }
    &__text {
      width: calc(100% - 310px);
      h4,
      .m-h4 {
        margin-top: 0;
        transition: $transition;
        &.newwindow {
          display: inline-block;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            bottom: 7px;
            margin-left: 10px;
            background: url(/assets/img/common/icon_newwindow.png)
              center/contain no-repeat;
          }
        }
      }
      &__ttl {
        font-size: 2rem;
        line-height: 1.4;
        margin-bottom: 25px;
        font-weight: 500;
      }
      .m-movie, .m-movie--small, .m-movie--100per, .m-movie--mp4 {
        position: relative;
        z-index: 1;
      }
    }
    &:hover {
      &.m-imagetext--link {
        .m-imagetext {
          &__text {
            h4,
            .m-h4 {
              color: $c-accent;
            }
          }
          &__img {
            img {
              transform: scale(1.1);
            }
          }
        }
      }
    }
    @include sp {
      display: block;
      margin-top: 40px;
      padding-top: 40px;
      span.pdf {
        &::before {
          top: 3px;
        }
      }
      &__img {
        width: 100%;
        height: 180px;
        max-height: inherit;
        img {
          height: 100%;
          width: auto;
          object-fit: contain;
        }
      }
      &__text {
        margin-top: 30px;
        width: 100%;
        h4,
        .m-h4 {
          &.newwindow {
            &::after {
              bottom: 5px;
            }
          }
        }
        &__ttl {
          font-size: 1.7rem;
          margin-bottom: 15px;
        }
      }
    }
  }

  .m-text-link {
    font-size: 1.6rem;
    line-height: 1.6;
    &__sub {
      color: $c-sub;
    }
    &__link {
      padding-left: 20px;
      transition: $transition;
      position: relative;
      display: inline-block;
      &::before {
        content: "";
        position: absolute;
        width: 7px;
        height: 10px;
        top: 8px;
        left: 5px;
        background: url(/assets/img/common/icon_arrow_right.png) center/contain
          no-repeat;
      }
      &.newwindow {
        &::after {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          bottom: 7px;
          margin-left: 5px;
          // right: 0px;
          background: url(/assets/img/common/icon_newwindow.png) center/contain
            no-repeat;
        }
      }
      &.new {
        &::after {
          content: "";
          position: absolute;
          bottom: 2px;
          margin-left: 5px;
          width: 36px;
          height: 20px;
          background: url(/assets/img/common/icon_new.png) center/contain
            no-repeat;
        }
      }
      &.zip {
        padding-left: 25px;
        &::before {
          content: "";
          position: absolute;
          width: 18px;
          height: 17px;
          top: 5px;
          left: 0px;
          background: url(/assets/img/common/icon_zip.png) center/contain
            no-repeat;
        }
      }
      &.pdf {
        padding-left: 25px;
        &::before {
          content: "";
          position: absolute;
          width: 18px;
          height: 17px;
          top: 5px;
          left: 0px;
          background: url(/assets/img/common/icon_pdf.png) center/contain
            no-repeat;
        }
      }
      &.xls {
        padding-left: 25px;
        &::before {
          content: "";
          position: absolute;
          width: 18px;
          height: 17px;
          top: 5px;
          left: 0px;
          background: url(/assets/img/common/icon_xls.png) center/contain
            no-repeat;
        }
      }
      &.doc {
        padding-left: 25px;
        &::before {
          content: "";
          position: absolute;
          width: 18px;
          height: 17px;
          top: 5px;
          left: 0px;
          background: url(/assets/img/common/icon_doc.png) center/contain
            no-repeat;
        }
      }
      &.dl {
        padding-left: 25px;
        &::before {
          content: "";
          position: absolute;
          width: 18px;
          height: 17px;
          top: 5px;
          left: 0px;
          background: url(/assets/img/common/icon_dl.png) center/contain
            no-repeat;
        }
      }
      &.arrow {
        padding-left: 25px;
        &::before {
          content: "";
          position: absolute;
          width: 10px;
          height: 16px;
          top: 5px;
          left: 4px;
          background: url(/assets/img/common/icon_arrow_index.png)
            center/contain no-repeat;
        }
      }
      &.ebook {
        padding-left: 25px;
        &::before {
          content: "";
          position: absolute;
          width: 18px;
          height: 18px;
          top: 5px;
          left: 0px;
          background: url(/assets/img/common/icon_ebook.png) center/contain
            no-repeat;
        }
      }
      &:hover {
        color: $c-accent;
      }
    }
    + .m-text-link {
      margin-top: 15px;
    }
  }

  // ボタン
  .m-btn--center {
    display: flex;
    justify-content: center;
  }
  .m-btn--left {
    display: flex;
    justify-content: flex-start;
    @include sp {
      &.m-btn--center--sp {
        justify-content: center;
      }
    }
  }
  .m-btn--right {
    display: flex;
    justify-content: flex-end;
    @include sp {
      &.m-btn--center--sp {
        justify-content: center;
      }
    }
  }
  .m-btn--2col {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .m-btn {
      &:nth-child(n + 2) {
        margin-left: 20px;
      }
    }
    @include sp {
      flex-direction: column;
      .m-btn {
        &:nth-child(n + 2) {
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }
  }
  .m-btn--small {
    min-width: 150px;
    min-height: 45px;
    border: 2px solid #dfdfe8;
    font-size: 1.5rem;
    border-radius: 22.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    transition: $transition;
    &:hover {
      background: #dfdfe8;
    }
  }
  .m-btn--normal {
    min-width: 290px;
    min-height: 56px;
    padding: 14px 30px;
    border-radius: 60px;
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    transition: $transition;
    text-align: center;
    cursor: pointer;
    // background: linear-gradient(to right,#dfdfe8,#dfdfe8);
    box-shadow: inset 2px 2px 0px 0px #dfdfe8, inset 0 0 2px 2px #dfdfe8;

    position: relative;
    z-index:1;
    &::before {
      position:absolute;
      top:0;
      left:0;
      z-index:-1;
      box-sizing:border-box;
      border-radius:60px;
      padding:2px;
      width:100%;
      height:100%;
      background:#fff;
      background-clip:content-box;
      content:"";
    }
    &.m-btn--pdf {
      position: relative;
      padding: 14px 40px;
      &::after {
        position: absolute;
        content: "";
        width: 18px;
        height: 17px;
        top: 50%;
        right: 15px;
        transform: translate(0, -50%);
        background: url(/assets/img/common/icon_pdf.png) center/contain
          no-repeat;
      }
    }
    &.m-btn--newwindow {
      position: relative;
      padding: 14px 40px;
      &::after {
        position: absolute;
        content: "";
        width: 12px;
        height: 12px;
        top: 50%;
        right: 20px;
        transform: translate(0, -50%);
        background: url(/assets/img/common/icon_newwindow.png) center/contain
          no-repeat;
      }
    }
    &:hover {
      box-shadow: inset 2px 2px 0px 0px #57c5cf, inset 0 0 2px 2px #2760bf;
      // background: linear-gradient(to right,#57c5cf,#2760bf);
    }
    @include sp {
      width: 290px;
    }
  }
  .m-btn--disabled {
    pointer-events: none;
    color: #999999;
    background: #efeff3;
    border: none;
    box-shadow: none;
    &::before {
      display: none;
    }
  }
  .m-btn--shadow {
    border: none;
    box-shadow: 0px 0px 35px 0px rgba(2, 34, 110, 0.12);
    transition: $transition;
    background: #fff;
    &::before {
      display: none;
    }
    &:hover {
      box-shadow: none;
      background: #dfdfe8;
    }
  }
  .m-btn--blue {
    background: $c-accent;
    color: #fff;
    border: none;
    box-shadow: none;
    &::before {
      display: none;
    }
    &.m-btn--pdf {
      &::after {
        background: url(/assets/img/common/icon_pdf_white.png) center/contain
          no-repeat;
      }
    }
    &.m-btn--newwindow {
      &::after {
        background: url(/assets/img/common/icon_newwindow_white.png)
          center/contain no-repeat;
      }
    }
    &:hover {
      background: #043a95;
      box-shadow: none;
    }
  }
  .m-btn--red {
    background: #e60e00;
    color: #fff;
    border: none;
    box-shadow: none;
    &::before {
      display: none;
    }
    &:hover {
      opacity: .7;
      box-shadow: none;
    }
  }
  .m-btn--gradient {
    background: #57c5cf;
    box-shadow: none;
    color: #fff;
    border: none;
    position: relative;
    overflow: hidden;
    &.m-btn--pdf,
    &.m-btn--newwindow,
    &.m-btn--dl {
      padding: 14px 40px;
      &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: -webkit-linear-gradient(
          0deg,
          rgb(39, 96, 191) 0%,
          rgb(58, 140, 220) 50%,
          rgb(87, 197, 207) 100%
        );
        opacity: 0;
        transition: $transition;
        width: inherit;
        height: inherit;
        transform: inherit;
      }
    }
    &.m-btn--dl {
      .m-btn--gradient__inner {
        padding-left: 28px;
        width: auto;
        &::before {
          position: absolute;
          content: "";
          width: 18px;
          height: 18px;
          top: 50%;
          left: 0px;
          transform: translate(0, -50%);
          background: url(/assets/img/common/icon_dl_white.png) center/contain
            no-repeat;
        }
      }
    }
    &.m-btn--pdf {
      .m-btn--gradient__inner {
        &::after {
          position: absolute;
          content: "";
          width: 18px;
          height: 17px;
          top: 50%;
          right: -25px;
          transform: translate(0, -50%);
          background: url(/assets/img/common/icon_pdf_white.png) center/contain
            no-repeat;
        }
      }
    }
    &.m-btn--newwindow {
      .m-btn--gradient__inner {
        &::after {
          position: absolute;
          content: "";
          width: 12px;
          height: 12px;
          top: 50%;
          right: -20px;
          transform: translate(0, -50%);
          background: url(/assets/img/common/icon_newwindow_white.png)
            center/contain no-repeat;
        }
      }
    }

    &__inner {
      position: relative;
      width: 100%;
      z-index: 1;
    }
    &::before,
    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &::before {
      background: -webkit-linear-gradient(
        0deg,
        rgb(87, 197, 207) 0%,
        rgb(58, 140, 220) 50%,
        rgb(39, 96, 191) 100%
      );
    }
    &::after {
      background: -webkit-linear-gradient(
        0deg,
        rgb(39, 96, 191) 0%,
        rgb(58, 140, 220) 50%,
        rgb(87, 197, 207) 100%
      );
      opacity: 0;
      transition: $transition;
    }
    &:hover {      
      box-shadow: none;
      &::after {
        opacity: 1;
      }
    }
    &.m-btn--disabled {
      pointer-events: none;
      color: #999999;
      background: #efeff3;
      border: none;
      &::before,
      &::after {
        background: #efeff3;
      }
    }
  }
  .m-btn--gray {
    background: #9a9a9a;
    box-shadow: none;
    color: #fff;
    border: none;
    &::before {
      display: none;
    }
    &.m-btn--pdf {
      &::after {
        background: url(/assets/img/common/icon_pdf_white.png) center/contain
          no-repeat;
      }
    }
    &.m-btn--newwindow {
      &::after {
        background: url(/assets/img/common/icon_newwindow_white.png)
          center/contain no-repeat;
      }
    }
    &:hover {
      box-shadow: none;
      background: #b2b2b2;
    }
  }

  .m-btn-square {
    &__inner {
      min-height: 98px;
      padding: 20px 40px 20px 30px;
      box-shadow: 0px 0px 35px 0px rgba(2, 34, 110, 0.1);
      position: relative;
      font-weight: 500;
      font-size: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: $transition;
      &::after {
        position: absolute;
        content: "";
        width: 8px;
        height: 15px;
        top: 50%;
        right: 25px;
        transform: translate(0, -50%);
        background: url(/assets/img/common/icon_arrow_right.png) center/contain
          no-repeat;
        transition: $transition;
      }
      &:hover {
        color: $c-accent;
        background: #f4f4f8;
        box-shadow: none;
        &::after {
          right: 21px;
        }
      }
    }
    @include sp {
      &__inner {
        padding: 20px 45px 20px 20px;
        font-size: 1.6rem;
      }
    }
  }

  .m-image-index {
    display: flex;
    flex-wrap: wrap;
    &__item {
      position: relative;
      &.no-link {
        &:hover {
          .m-image-index__item__img {
            img {
              transform: scale(1);
            }
          }
          .m-image-index__item__ttl {
            color: $c-base;
          }
        }
      }
      &__img {
        overflow: hidden;
        img {
          transition: $transition;
        }
        &--aspect {
          position: relative;
          width: 100%;
          aspect-ratio: 4/3;
          overflow: hidden;
          >img {
            position: absolute;
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
          }
          + .m-image-index__item__ttl{
            text-align: center;
          }
        }
      }

      &__ttl {
        margin-top: 30px;
        font-size: 2rem;
        line-height: 1.4;
        font-weight: 500;
        transition: $transition;
        &.newwindow {
          position: relative;
          &::after {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            bottom: 5px;
            margin-left: 5px;
            background: url(/assets/img/common/icon_newwindow.png) center/contain no-repeat;
          }
        }
      }
      &__txt {
        margin-top: 20px;
        font-size: 1.5rem;
        line-height: 1.8;
        color: $c-sub;
      }
      &__link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      &:hover {
        .m-image-index__item__img {
          img {
            transform: scale(1.1);
          }
        }
        .m-image-index__item__ttl {
          color: $c-accent;
        }
      }
    }
      &__ytube {
        display: block;
        position: relative;
        width: 100%;
        .m-image-index__item__ttl {
          text-align: center;
        }
        iframe {
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
        a {
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
        &--inner {
          position: relative;
          width: 100%;
          aspect-ratio: 16 / 9;
          margin-left: auto;
          margin-right: auto;
          padding-top: 56.25%;
        }
      }
    &.m-image-index--col2 {
      .m-image-index {
        &__item {
          width: calc((100% - 50px) / 2);
          margin-right: 50px;
          &:nth-child(2n) {
            margin-right: 0;
          }
          &:nth-child(n + 3) {
            margin-top: 50px;
          }
          &__ttl {
            margin-top: 30px;
            font-size: 2rem;
            &.newwindow {
              &::after {
                bottom: 8px;
              }
            }
          }
          &__txt {
            margin-top: 20px;
            font-size: 1.5rem;
          }
        }
      }
    }
    &.m-image-index--col3 {
      .m-image-index {
        &__item {
          width: calc((100% - 80px) / 3);
          margin-right: 40px;
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:nth-child(n + 4) {
            margin-top: 50px;
          }
          &__ttl {
            margin-top: 25px;
            font-size: 1.8rem;
            &.newwindow {
              &::after {
                bottom: 5px;
              }
            }
          }
          &__txt {
            margin-top: 15px;
          }
        }
      }
    }
    &.m-image-index--col4 {
      .m-image-index {
        &__item {
          width: calc((100% - 120px) / 4);
          margin-right: 40px;
          &:nth-child(4n) {
            margin-right: 0;
          }
          &:nth-child(n + 5) {
            margin-top: 50px;
          }
          &__ttl {
            margin-top: 20px;
            font-size: 1.6rem;
            &.newwindow {
              &::after {
                bottom: 5px;
              }
            }
            >span {
              font-size: 12px;
              margin-left: 10px;
              @include sp{
                font-size: 10px;
                margin-left: 8px;
              }
            }
          }
          &__txt {
            margin-top: 10px;
          }
        }
      }
    }
    @include sp {
      &.m-image-index--col2 {
        flex-direction: column;
        .m-image-index {
          &__item {
            width: 100%;
            margin-right: 0;
            &:nth-child(2n) {
              margin-right: 0;
            }
            &:nth-child(n + 2) {
              margin-top: 40px;
            }
            &:nth-child(n + 3) {
              margin-top: 40px;
            }
            &__ttl {
              margin-top: 22px;
              font-size: 1.7rem;
              &.newwindow {
                &::after {
                  bottom: 5px;
                }
              }
            }
            &__txt {
              margin-top: 15px;
              font-size: 1.5rem;
            }
          }
        }
      }
      &.m-image-index--col3 {
        flex-direction: column;
        .m-image-index {
          &__item {
            width: 100%;
            margin-right: 0;
            &:nth-child(3n) {
              margin-right: 0;
            }
            &:nth-child(n + 2) {
              margin-top: 40px;
            }
            &:nth-child(n + 4) {
              margin-top: 40px;
            }
            &__ttl {
              margin-top: 22px;
              font-size: 1.7rem;
            }
            &__txt {
              margin-top: 15px;
              font-size: 1.5rem;
            }
          }
        }
      }
      &.m-image-index--col4 {
        flex-direction: row;
        .m-image-index {
          &__item {
            width: calc((100% - 15px) / 2);
            margin-right: 15px;
            &:nth-child(2n) {
              margin-right: 0;
            }
            &:nth-child(n + 3) {
              margin-top: 25px;
            }
            &:nth-child(n + 5) {
              margin-top: 25px;
            }
            &__ttl {
              margin-top: 15px;
              font-size: 1.6rem;
              &.newwindow {
                &::after {
                  bottom: 4px;
                }
              }
            }
            &__txt {
              margin-top: 12px;
            }
          }
        }
      }
    }
  }

  .m-noimage-index {
    display: flex;
    flex-wrap: wrap;
    &__item {
      padding: 35px;
      box-shadow: 0px 0px 35px 0px rgba(2, 34, 110, 0.12);
      border-left: 2px solid #57c5cf;
      border-image: -webkit-linear-gradient(
        90deg,
        rgb(87, 197, 207) 0%,
        rgb(73, 169, 214) 24%,
        rgb(58, 140, 220) 50%,
        rgb(49, 118, 206) 79%,
        rgb(39, 96, 191) 100%
      );
      border-image-slice: 1;
      position: relative;
      transition: $transition;
      background: #fff;
      &__ttl {
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 1.6;
        &.newwindow {
          position: relative;
          display: inline-block;
          &::after {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            bottom: 7px;
            margin-left: 5px;
            background: url(/assets/img/common/icon_newwindow.png)
              center/contain no-repeat;
          }
        }
        &.pdf {
          position: relative;
          display: inline-block;
          &::after {
            content: "";
            position: absolute;
            width: 18px;
            height: 17px;
            bottom: 4px;
            margin-left: 5px;
            background: url(/assets/img/common/icon_pdf.png) center/contain
              no-repeat;
          }
        }
      }
      &__txt {
        margin-top: 18px;
        font-size: 1.5rem;
        line-height: 1.8;
        color: $c-sub;
      }
      &__link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      &:hover {
        background: #f9f9fb;
        box-shadow: none;
        .m-noimage-index__item__ttl {
          color: $c-accent;
        }
      }
    }
    &.m-noimage-index--col2 {
      .m-noimage-index {
        &__item {
          width: calc((100% - 50px) / 2);
          margin-right: 50px;
          &:nth-child(2n) {
            margin-right: 0;
          }
          &:nth-child(n + 3) {
            margin-top: 50px;
          }
        }
      }
    }
    &.m-noimage-index--col3 {
      .m-noimage-index {
        &__item {
          padding: 30px;
          width: calc((100% - 54px) / 3);
          margin-right: 27px;
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:nth-child(n + 4) {
            margin-top: 27px;
          }
          &__ttl {
            font-size: 1.8rem;
          }
          &__txt {
            margin-top: 15px;
          }
        }
      }
    }
    &.m-noimage-index--col4 {
      .m-noimage-index {
        &__item {
          padding: 25px 30px;
          width: calc((100% - 60px) / 4);
          margin-right: 20px;
          &:nth-child(4n) {
            margin-right: 0;
          }
          &:nth-child(n + 5) {
            margin-top: 22px;
          }
          &__ttl {
            font-size: 1.6rem;
          }
          &__txt {
            margin-top: 15px;
          }
        }
      }
    }
    @include sp {
      flex-direction: column;
      &__item {
        padding: 25px 20px;
        &__ttl {
          font-size: 1.6rem;
        }
        &__txt {
          margin-top: 12px;
        }
      }
      &.m-noimage-index--col2 {
        .m-noimage-index {
          &__item {
            width: 100%;
            margin-right: 0;
            &:nth-child(2n) {
              margin-right: 0;
            }
            &:nth-child(n + 2) {
              margin-top: 15px;
            }
            &:nth-child(n + 3) {
              margin-top: 15px;
            }
          }
        }
      }
      &.m-noimage-index--col3 {
        .m-noimage-index {
          &__item {
            padding: 25px 20px;
            width: 100%;
            margin-right: 0;
            &:nth-child(3n) {
              margin-right: 0;
            }
            &:nth-child(n + 2) {
              margin-top: 15px;
            }
            &:nth-child(n + 4) {
              margin-top: 15px;
            }
            &__ttl {
              font-size: 1.6rem;
            }
            &__txt {
              margin-top: 12px;
            }
          }
        }
      }
      &.m-noimage-index--col4 {
        .m-noimage-index {
          &__item {
            padding: 25px 20px;
            width: 100%;
            margin-right: 0;
            &:nth-child(4n) {
              margin-right: 0;
            }
            &:nth-child(n + 2) {
              margin-top: 15px;
            }
            &:nth-child(n + 5) {
              margin-top: 15px;
            }
            &__ttl {
              font-size: 1.6rem;
            }
            &__txt {
              margin-top: 12px;
            }
          }
        }
      }
    }
  }

  .m-index-text {
    background: #f4f4f8;
    padding: 35px 35px 15px;
    display: flex;
    flex-wrap: wrap;
    &__item {
      margin-right: 25px;
      margin-bottom: 15px;
      padding-right: 25px;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: 1px;
        height: 16px;
        top: 50%;
        transform: translate(0, -50%);
        right: 0px;
        background: #cbcbcb;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
      &__link {
        position: relative;
        font-size: 1.6rem;
        line-height: 1.4;
        transition: $transition;
        display: inline-block;
        &:hover {
          color: $c-accent;
        }
        &.current {
          pointer-events: none;
          color: $c-accent;
          position: relative;
          display: inline-block;
          &::after {
            content: "";
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 3px;
            background: $c-accent;
            transform: scaleX(1);
          }
        }
        &__sub {
          color: $c-sub;
        }
        &.pdf {
          padding-left: 23px;
          &::before {
            content: "";
            position: absolute;
            width: 18px;
            height: 17px;
            top: 2px;
            left: 0;
            background: url(/assets/img/common/icon_pdf.png) center/contain
              no-repeat;
          }
        }
        &.newwindow {
          padding-right: 17px;
          &::after {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            bottom: 7px;
            margin-left: 5px;
            background: url(/assets/img/common/icon_newwindow.png)
              center/contain no-repeat;
          }
        }
      }
    }
    @include sp {
      padding: 15px 15px 30px;
      flex-direction: column;
      &__item {
        width: 100%;
        border-bottom: 1px solid #cccccc;
        margin-right: 0;
        margin-bottom: 0;
        &::after {
          display: none;
        }
        &__link {
          padding: 15px 0 15px 16px;
          font-size: 1.5rem;
          &::before {
            position: absolute;
            content: "";
            width: 6px;
            height: 9px;
            top: 21px;
            left: 0px;
            background: url(/assets/img/common/icon_arrow_right.png)
              center/contain no-repeat;
          }
          &.current {
            &::after {
              width: calc(100% - 16px);
              height: 2px;
              left: 16px;
              bottom: 14px;
            }
          }
          &.pdf {
            padding-left: 16px;
            &::before {
              width: 14px;
              height: 14px;
              top: 18px;
              left: -2px;
            }
          }
          &.newwindow {
            &::after {
              bottom: 18px;
            }
          }
        }
      }
    }
  }

  .m-index-text-ac {
    background: #f4f4f8;
    padding: 35px 35px 15px;
    &__menu {
      display: none;
    }
    &__wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    &__img {
      width: 40px;
      height: 40px;
      margin-right: 8px;
      display: inline-block;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    &__item {
      margin-right: 25px;
      margin-bottom: 15px;
      &__txt {
        display: inline-block;
      }
      &__link {
        position: relative;
        padding-left: 34px;
        font-size: 1.6rem;
        line-height: 1.4;
        transition: $transition;
        display: inline-flex;
        align-items: center;
        &::before {
          position: absolute;
          content: "";
          width: 24px;
          height: 24px;
          top: 50%;
          left: 0px;
          background: url(/assets/img/common/icon_arrow_link.png) center/contain
            no-repeat;
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
          border-radius: 24px;
          transition: $transition;
          transform: translate(0, -50%);
        }
        &:hover {
          color: $c-accent;
          &::before {
            box-shadow: none;
          }
        }
      }
    }
    @include sp {
      padding: 0 15px 0;
      &__wrap {
        flex-direction: column;
        display: none;
        padding-bottom: 20px;
      }
      &__menu {
        display: block;
        padding: 15px 0;
        position: relative;
        cursor: pointer;
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
          -webkit-transition: all 0.2s linear;
          transition: all 0.2s linear;
        }
        &::before {
          width: 17px;
          height: 17px;
          background: url(/assets/img/common/icon_plus.png) center/contain
            no-repeat;
          opacity: 1;
        }
        &::after {
          width: 17px;
          height: 1px;
          background: #002d78;
          opacity: 0;
        }
        &.active {
          &::before {
            opacity: 0;
          }
          &::after {
            opacity: 1;
          }
        }
      }
      &__item {
        width: 100%;
        margin-right: 0;
        margin-bottom: 0;
        &__link {
          padding: 7px 0 7px 32px;
          font-size: 1.5rem;
          display: flex;
          &::before {
            width: 22px;
            height: 22px;
            top: 50%;
            transform: translate(0, -50%);
          }
        }
      }
    }
  }

  .m-index-lower {
    margin-top: 140px;
    border-top: 1px solid #cccccc;
    &__inner {
      margin: 60px 0 100px;
      display: flex;
    }
    &__menu {
      font-size: 1.7rem;
      line-height: 1.6;
      width: 265px;
      margin-right: 50px;
      font-weight: 500;
    }
    &__item__wrap {
      width: calc(100% - 315px);
      display: flex;
      flex-wrap: wrap;
    }
    &__item {
      margin-right: 25px;
      margin-bottom: 15px;
      &__link {
        position: relative;
        padding-left: 16px;
        font-size: 1.6rem;
        line-height: 1.4;
        transition: $transition;
        display: inline-block;
        &::before {
          position: absolute;
          content: "";
          width: 6px;
          height: 9px;
          top: 7px;
          left: 0px;
          background: url(/assets/img/common/icon_arrow_right.png)
            center/contain no-repeat;
        }
        &:hover {
          color: $c-accent;
        }
      }
    }
    @include sp {
      margin-top: 0;
      border: none;
      &__inner {
        display: block;
        box-shadow: 0px 0px 35px 0px rgba(2, 34, 110, 0.1);
        border-radius: 6px;
        margin: 60px 0 40px;
      }
      &__menu {
        padding: 15px 45px 15px 20px;
        font-size: 1.6rem;
        width: 100%;
        margin-right: 0;
        position: relative;
        cursor: pointer;
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 15px;
          -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
          -webkit-transition: all 0.2s linear;
          transition: all 0.2s linear;
        }
        &::before {
          width: 17px;
          height: 17px;
          background: url(/assets/img/common/icon_plus.png) center/contain
            no-repeat;
          opacity: 1;
        }
        &::after {
          width: 17px;
          height: 1px;
          background: #002d78;
          opacity: 0;
        }
        &.active {
          border-radius: 6px 6px 0 0;
          &::before {
            opacity: 0;
          }
          &::after {
            opacity: 1;
          }
        }
      }
      &__item__wrap {
        width: 100%;
        display: block;
        padding: 15px 0 20px;
        border-top: 1px solid #cccccc;
        display: none;
      }
      &__item {
        margin-right: 0;
        margin-bottom: 0;
        &__link {
          padding: 7px 15px 7px 35px;
          font-size: 1.5rem;
          display: block;
          &::before {
            top: 13px;
            left: 20px;
          }
        }
      }
    }
  }

  .m-image {
    &.m-image-pos--left {
      .m-image__image {
        text-align: left;
      }
    }
    &.m-image-pos--right {
      .m-image__image {
        text-align: right;
      }
    }
    &__caption {
      margin-top: 15px;
      font-size: 1.4rem;
      color: $c-sub;
      line-height: 1.6;
      text-align: center;
      &--635 {
        max-width: 635px;
        margin: 15px auto 0;
      }
    }
    &__image {
      text-align: center;
      img {
        max-width: 100%;
        width: auto;
      }
    }
    &--col1 {
      margin-left: auto;
      margin-right: auto;
      &.m-max600px {
        max-width: 600px;
      }
      &.m-max720px {
        max-width: 720px;
      }
      &.m-max540px {
        max-width: 540px;
      }
      .m-image {
        &__item {
          width: 100%;
        }
        &__image {
          max-width: 1200px;
          margin: 0 auto;
        }
      }
    }
    &--col2 {
      display: flex;
      flex-wrap: wrap;
      .m-image {
        &__item {
          width: calc((100% - 50px) / 2);
          margin-right: 50px;
          &:nth-child(2n) {
            margin-right: 0;
          }
          &:nth-child(n + 3) {
            margin-top: 50px;
          }
        }
        &__image {
          max-width: 575px;
          margin: 0 auto;
        }
      }
      @include sp {
        .m-image {
          &__item {
            width: 100%;
            margin-right: 0;
            &:nth-child(2n) {
              margin-right: 0;
            }
            &:nth-child(n + 2) {
              margin-top: 30px;
            }
            &:nth-child(n + 3) {
              margin-top: 30px;
            }
          }
        }
      }
    }
    &--col3 {
      display: flex;
      flex-wrap: wrap;
      .m-image {
        &__item {
          width: calc((100% - 80px) / 3);
          margin-right: 40px;
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:nth-child(n + 4) {
            margin-top: 40px;
          }
        }
        &__image {
          max-width: 373px;
          margin: 0 auto;
        }
      }
      @include sp {
        .m-image {
          &__item {
            width: 100%;
            margin-right: 0;
            &:nth-child(2n) {
              margin-right: 0;
            }
            &:nth-child(n + 2) {
              margin-top: 30px;
            }
            &:nth-child(n + 4) {
              margin-top: 30px;
            }
          }
        }
      }
    }
    &--col4 {
      display: flex;
      flex-wrap: wrap;
      .m-image {
        &__item {
          width: calc((100% - 120px) / 4);
          margin-right: 40px;
          &:nth-child(4n) {
            margin-right: 0;
          }
          &:nth-child(n + 5) {
            margin-top: 40px;
          }
        }
        &__image {
          max-width: 270px;
          margin: 0 auto;
        }
      }
      @include sp {
        .m-image {
          &__item {
            width: calc((100% - 15px) / 2);
            margin-right: 15px;
            &:nth-child(2n) {
              margin-right: 0;
            }
            &:nth-child(n + 3) {
              margin-top: 15px;
            }
            &:nth-child(n + 5) {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }

  .m-table {
    &__content {
      width: 100%;
      border-collapse: collapse;
    }
    &__head__item,
    &__data {
      font-size: 1.5rem;
      line-height: 1.6;
      padding: 20px 15px;
      border: 1px solid #cccccc;
    }
    &__head {
      .m-table__head__item {
        background: #dcebf7;
        font-weight: 500;
      }
    }
    &__body {
      .m-table__head__item {
        background: #eef4f9;
        font-weight: 500;
      }
      .m-table__data {
        background: #fff;
        .m-text--normal {
          font-size: 1.5rem;
          line-height: 1.6;
        }
        .m-text--normal + .m-list--dot {
          margin-top: 5px;
        }
        .m-list--dot {
          &__item {
            &:nth-child(n + 2) {
              margin-top: 5px;
            }
          }
        }
      }
    }
    &__colgroup {
      col {
        @for $i from 1 through 99 {
          &.m-width#{$i}per {
            width: $i * 1%;
          }
        }
      }
    }
    @include sp {
      &__body {
        .m-table__data {
          .m-text--normal { 
            font-size: 1.4rem;
          }
        }
      }
      &__head__item,
      &__data {
        font-size: 1.4rem;
        padding: 15px 10px;
        .m-text-link {
          &__link {
            padding-left: 16px;
            font-size: 1.4rem;
            &::before {
              width: 6px;
              height: 9px;
              top: 7px;
            }
          }
        }
      }
    }
    &__colgroup {
      col {
        @include sp {
          @for $i from 1 through 99 {
            &.m-width#{$i}per-sp {
              width: $i * 1%;
            }
          }
        }
      }
    }
    &--scroll {
      overflow-x: auto;
      @include sp {
        .m-table__content {
          min-width: 600px;
          &.m-table-780px-sp {
            min-width: 780px;
          }
          &.m-table-1200px-sp {
            min-width: 1200px;
          }
        }
      }
    }
  }

  .m-table-explanation {
    border-bottom: 1px solid #cccccc;
    &__content {
      width: 100%;
      border-collapse: collapse;
    }
    &__head__item,
    &__data {
      font-size: 1.5rem;
      line-height: 1.6;
      padding: 20px 15px;
      border: none;
    }
    &__row {
      border-top: 1px solid #cccccc;
    }
    &__head {
      .m-table-explanation__head__item {
        background: #f5f6f9;
        font-weight: 500;
      }
    }
    &__body {
      .m-table-explanation__head__item {
        background: #f5f6f9;
        font-weight: 500;
      }
      .m-table-explanation__data {
        background: #fff;
      }
    }
    &__colgroup {
      col {
        @for $i from 1 through 99 {
          &.m-width#{$i}per {
            width: $i * 1%;
          }
        }
      }
    }
    @include sp {
      &__head__item,
      &__data {
        font-size: 1.6rem;
        padding: 15px 10px;
      }
    }
    &--noliquid {
      @include sp {
        .m-table-explanation {
          &__row,
          &__head,
          &__head__item,
          &__data {
            display: block;
            width: 100%;
          }
          &__head__item {
            font-size: 1.6rem;
          }
          &__colgroup {
            col {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .m-image-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    &__link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: $transition;
      &:hover {
        background-color: rgba(255, 255, 255, 0.4);
      }
    }
    &__link-2 {
      &:hover {
        p {
          span {
            transition: $transition;
            opacity: 0.6;
          }
        }
      }
    }
    &__item {
      position: relative;
      h4,
      .m-h4 {
        margin-top: 0;
      }
    }
    &__image {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f7f7f7;
    }
    &__caption {
      font-size: 1.4rem;
      color: $c-sub;
      line-height: 1.6;
      margin-top: 15px;
      text-align: center;
      .icon_new,
      &.icon_new {
        display: inline-block;
        position: relative;
        text-indent: 40px;
        &::before {
          position: absolute;
          content: "";
          top: 2px;
          left: 0px;
          width: 36px;
          height: 20px;
          background: url(/assets/img/common/icon_new.png) center/contain
            no-repeat;
        }
      }
      .icon_pdf {
        &__size {
          padding-right: 22px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            width: 18px;
            height: 17px;
            bottom: 1px;
            right: 0px;
            background: url(/assets/img/common/icon_pdf.png) center/contain
              no-repeat;
          }
        }
      }
      .icon_ebook {
        display: inline-block;
        padding-right: 22px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 18px;
          height: 18px;
          bottom: 1px;
          right: 0px;
          background: url(/assets/img/common/icon_ebook.png) center/contain
            no-repeat;
        }
      }
    }
    &--type01 {
      .m-image-text {
        &__item {
          width: 575px;
          &:nth-child(2n) {
            width: calc(100% - 625px);
          }
        }
      }
    }
    &--type02 {
      .m-image-text {
        &__item {
          width: 375px;
          &:nth-child(2n) {
            width: calc(100% - 425px);
          }
        }
      }
    }
    &--type03 {
      .m-image-text {
        &__item {
          width: 250px;
          &:nth-child(2n) {
            width: calc(100% - 300px);
          }
        }
      }
    }
    &--type04 {
      .m-image-text {
        &__item {
          width: 130px;
          &:nth-child(2n) {
            width: calc(100% - 160px);
          }
        }
      }
    }
    &--reverse {
      flex-direction: row-reverse;
    }
    @include sp {
      flex-direction: column;
      &--type01,
      &--type02,
      &--type03 {
        .m-image-text {
          &__item {
            width: 100%;
            &:nth-child(2n) {
              width: 100%;
              margin-top: 30px;
            }
          }
        }
      }
      &--type04 {
        flex-direction: row;
        .m-image-text {
          &__item {
            width: 60px;
            &:nth-child(2n) {
              width: calc(100% - 80px);
            }
          }
        }
      }
    }
  }

  .m-box {
    padding: 40px 50px;
    &__ttl {
      font-size: 2rem;
      font-weight: 500;
      line-height: 1.6;
    }
    &__txt {
      font-size: 1.6rem;
      line-height: 2;
      margin-top: 20px;
    }
    &--bigpd {
      border-radius: 0;
      padding: 50px 40px;
    }
    &--gray {
      background: #f5f6f9;
    }
    &--info {
      background: #e5f2fd;
      .m-box {
        &__ttl {
          color: #004ab9;
        }
      }
    }
    &--caution {
      background: #fff9e2;
      .m-box {
        &__ttl {
          color: #ff7e00;
        }
      }
    }
    &--warning {
      background: #ffeeed;
      .m-box {
        &__ttl {
          color: #e60e00;
          position: relative;
          padding-left: 35px;
          &::before {
            position: absolute;
            content: "";
            top: 5px;
            left: 0px;
            width: 22px;
            height: 20px;
            background: url(/assets/img/common/icon_attention.png)
              center/contain no-repeat;
          }
        }
      }
    }
    @include sp {
      padding: 25px 20px;
      &__ttl {
        font-size: 1.7rem;
      }
      &__txt {
        font-size: 1.6rem;
        margin-top: 15px;
      }
      &--bigpd {
        padding: 40px 20px;
      }
    }
  }

  .m-ac01 {
    border-top: 1px solid #cccccc;
    &__item {
      border-bottom: 1px solid #cccccc;
      &.active {
        .m-ac01 {
          &__ttl {
            &::before {
              opacity: 1;
            }
            &::after {
              opacity: 0;
            }
          }
        }
      }
    }
    &__ttl {
      font-size: 2rem;
      line-height: 1.4;
      font-weight: 500;
      padding: 25px 60px 25px 20px;
      cursor: pointer;
      transition: $transition;
      position: relative;
      &__icon {
        position: relative;
        display: inline-block;
        padding-left: 25px;
        &::before {
          position: absolute;
          top: -7px;
          left: 0;
          content: "Q";
          font-family: Oswald-Light;
          font-size: 2.8rem;
          color: $c-accent;
        }
      }
      &::before,
      &::after {
        position: absolute;
        content: "";
        top: 50%;
        right: 20px;
        transform: translate(0, -50%);
        transition: $transition;
      }
      &::before {
        width: 20px;
        height: 2px;
        background: $c-accent;
        opacity: 0;
      }
      &::after {
        width: 20px;
        height: 20px;
        background: url(/assets/img/common/icon_plus.png) center/contain
          no-repeat;
      }
      @include pc {
        &:hover {
          background: #e2ebf3;
          color: $c-accent;
        }
      }
    }
    &__content {
      padding: 40px;
      background: #f5f6f9;
      display: none;
      &__ttl {
        font-size: 1.8rem;
        line-height: 1.4;
        font-weight: 500;
        margin-bottom: 15px;
        &:nth-of-type(n + 2) {
          margin-top: 35px;
        }
      }
      &__icon {
        position: relative;
        padding-left: 25px;
        &::before {
          position: absolute;
          top: -9px;
          left: 0;
          content: "A";
          font-family: Oswald-Light;
          font-size: 2.4rem;
          color: #6c7076;
        }
      }
    }
    @include sp {
      &__ttl {
        font-size: 1.7rem;
        padding: 15px 34px 15px 10px;
        &::before,
        &::after {
          right: 10px;
        }
        &::before {
          width: 14px;
          height: 2px;
        }
        &::after {
          width: 14px;
          height: 14px;
        }
        &__icon {
          padding-left: 20px;
          &::before {
            top: -4px;
            font-size: 2.2rem;
          }
        }
      }
      &__content {
        padding: 25px 20px;
        &__ttl {
          font-size: 1.6rem;
          margin-bottom: 10px;
          &:nth-of-type(n + 2) {
            margin-top: 25px;
          }
        }
        &__icon {
          padding-left: 20px;
          &::before {
            top: -2px;
            font-size: 1.8rem;
          }
        }
      }
    }
  }

  // アコーディオン / テキスト
  .m-ac02 {
    &__item {
      &.active {
        margin-bottom: 30px;
        .m-ac02 {
          &__ttl {
            &::before {
              opacity: 1;
            }
            &::after {
              opacity: 0;
            }
          }
        }
      }
    }
    &__ttl {
      font-size: 1.8rem;
      line-height: 1.6;
      font-weight: 500;
      padding: 10px 10px 10px 50px;
      margin-bottom: 5px;
      cursor: pointer;
      transition: $transition;
      position: relative;
      &::before,
      &::after {
        position: absolute;
        content: "";
        top: 50%;
        left: 0px;
        width: 35px;
        height: 35px;
        transform: translate(0, -50%);
        transition: $transition;
      }
      &::before {
        background: url(/assets/img/common/icon_minus_circle.png) center/contain
          no-repeat;
        opacity: 0;
      }
      &::after {
        background: url(/assets/img/common/icon_plus_circle.png) center/contain
          no-repeat;
      }
      @include pc {
        &:hover {
          color: $c-accent;
        }
      }
    }
    &__content {
      padding-left: 50px;
      display: none;
    }
    @include sp {
      &__item {
        &.active {
          margin-bottom: 20px;
        }
      }
      &__ttl {
        font-size: 1.6rem;
        padding: 10px 10px 10px 40px;
        margin-bottom: 0;
        &::before,
        &::after {
          width: 30px;
          height: 30px;
        }
      }
      &__content {
        padding-left: 40px;
        display: none;
      }
    }
  }
  // タブ
  .m-tabtext {
    &__index {
      display: flex;
      border-bottom: 1px solid #cccccc;
      overflow-x: auto;
      width: 100%;
      &__item {
        font-size: 1.6rem;
        color: #888888;
        padding-bottom: 7px;
        cursor: pointer;
        white-space: nowrap;
        transition: $transition;
        &:nth-child(n + 2) {
          margin-left: 30px;
        }
        &:hover {
          color: $c-accent;
        }
        &.active {
          font-weight: bold;
          color: $c-accent;
          border-bottom: 3px solid #0055a5;
          pointer-events: none;
        }
      }
    }
    &__content {
      margin-top: 40px;
      &__item {
        display: none;
        &.active {
          display: block;
        }
      }
    }
    @include sp {
      &__index {
        &__item {
          font-size: 1.4rem;
          padding-bottom: 15px;
          &:nth-child(n + 2) {
            margin-left: 20px;
          }
        }
      }
      &__content {
        margin-top: 25px;
      }
    }
  }
  .m-newstab {
    &__index {
      display: flex;
      border-bottom: 1px solid #cccccc;
      overflow-x: auto;
      width: 100%;
      &__item {
        font-size: 1.6rem;
        color: #888888;
        padding-bottom: 7px;
        cursor: pointer;
        white-space: nowrap;
        transition: $transition;
        &:nth-child(n + 2) {
          margin-left: 30px;
        }
        &:hover {
          color: $c-accent;
        }
        &.active {
          font-weight: bold;
          color: $c-accent;
          border-bottom: 3px solid #0055a5;
          pointer-events: none;
        }
      }
    }
    &__content {
      margin-top: 40px;
      &__item {
        display: none;
        &.active {
          display: block;
        }
      }
    }
    @include pcmin1200 {
      &__index {
        &__item {
          font-size: vwm(16px);
          padding-bottom: vwm(7px);
          &:nth-child(n + 2) {
            margin-left: vwm(30px);
          }
        }
      }
    }
    @include sp {
      &__index {
        &__wrap {
          background: #f4f4f8;
          padding: 25px 15px 35px;
        }
        &__category {
          font-size: 1.5rem;
          font-weight: 500;
          margin-bottom: 8px;
        }
        position: relative;
        border: none;
        select {
          height: 50px;
          padding: 0 15px;
          width: 100%;
          border-radius: 8px;
          background: #fff;
          border: 2px solid #d1d1d8;
          font-size: 1.6rem;
          outline-color: #002d78;
        }
        &::after {
          position: absolute;
          content: "";
          top: 50%;
          right: 35px;
          width: 15px;
          height: 10px;
          -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
          background: url(/assets/img/common/icon_arrow_bottom.png)
            center/contain no-repeat;
          pointer-events: none;
        }
      }
      &__content {
        margin-top: 25px;
      }
    }
  }

  .m-contact {
    padding: 50px;
    text-align: center;
    box-shadow: 0px 0px 35px 0px rgba(2, 34, 110, 0.12);
    border-top: 4px solid #57c5cf;
    border-image: -webkit-linear-gradient(
      -180deg,
      rgb(87, 197, 207) 0%,
      rgb(73, 169, 214) 24%,
      rgb(58, 140, 220) 50%,
      rgb(49, 118, 206) 79%,
      rgb(39, 96, 191) 100%
    );
    border-image-slice: 1;
    .m-list--annotation {
      text-align: left;
      &.m-tac {
        text-align: center;
      }
    }
    &__ttl {
      font-size: 3rem;
      font-weight: inherit;
      line-height: inherit;
      border-top: none;
      padding: 0;
      position: inherit;
      margin-top: 0;
      &::before {
        display: none;
      }
      &.border {
        border-top: 1px solid #ccc;
        margin-top: 50px;
        padding-top: 30px;
      }
    }
    &__txt {
      font-size: 1.6rem;
      margin-top: 25px;
      &__sub {
        color: $c-sub;
      }
    }
    &__box {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      &__item {
        width: 50%;
        padding: 0 50px;
        display: flex;
        justify-content: flex-end;
        &--col1 {
          width: auto;
        }
        &__tel,
        &__tel01,
        &__fax,
        &__free,
        &__navi {
          font-family: Oswald-Regular;
          font-size: 3.6rem;
          line-height: inherit;
          a {
            color: $c-base;
            border-bottom: none;
            display: inline-block;
            position: relative;
            padding-left: 63px;
            &::before {
              position: absolute;
              content: "";
              top: 50%;
              left: 0px;
              width: 48px;
              height: 32px;
              transform: translate(0, -50%);
              background: url(/assets/img/common/icon_tel.png) center/contain
                no-repeat;
            }
          }
        }
        &__tel {
          a {
            &::before {
              background: url(/assets/img/common/icon_tel.png) center/contain
                no-repeat;
            }
          }
        }
        &__tel01 {
          a {
            &::before {
              background: url(/assets/img/common/icon_tel01.png) center/contain
                no-repeat;
            }
          }
        }
        &__fax {
          a {
            &::before {
              background: url(/assets/img/common/icon_fax.png) center/contain
                no-repeat;
            }
          }
        }
        &__free {
          a {
            &::before {
              background: url(/assets/img/common/icon_freedial.png)
                center/contain no-repeat;
            }
          }
        }
        &__navi {
          a {
            &::before {
              background: url(/assets/img/common/icon_navidial.png)
                center/contain no-repeat;
            }
          }
        }
        &__sub {
          font-size: 1.3rem;
          color: $c-sub;
          + .m-text-link {
            margin-top: 5px;
          }
        }
        .m-text-link {
          &__link {
            &::before {
              top: 50%;
              transform: translate(0, -50%);
            }
          }
        }
        &:nth-child(n + 2) {
          border-left: 1px solid #eeeeee;
          display: flex;
          justify-content: flex-start;
        }
      }
    }
    &__inline {
      margin-top: 10px;
      .m-text-link {
        display: inline-block;
        &:nth-child(n + 2) {
          margin-top: 0;
          padding-left: 20px;
        }
      }
    }
    @include sp {
      padding: 40px 25px;
      &__ttl {
        font-size: 2.4rem;
      }
      &__txt {
        font-size: 1.5rem;
        &__sub {
          margin-top: 5px;
          display: block;
        }
      }
      &__box {
        margin-top: 25px;
        flex-direction: column;
        &__item {
          width: 100%;
          padding: 0;
          justify-content: center;
          &__tel,
          &__fax,
          &__free,
          &__navi {
            font-size: 2.4rem;
            min-width: 290px;
            a {
              padding-left: 38px;
              &::before {
                width: 30px;
                height: 20px;
              }
            }
          }
          &__tel,
          &__tel01,
          &__free,
          &__navi {
            border: 2px solid #dfdfe8;
            border-radius: 28px;
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &__sub {
            margin-top: 10px;
            + .m-text-link {
              margin-top: 5px;
            }
          }
          .m-text-link {
            font-size: 1.4rem;
          }
          &:nth-child(n + 2) {
            margin-top: 25px;
            padding-top: 25px;
            border-left: 0;
            border-top: 1px solid #eeeeee;
            justify-content: center;
          }
        }
      }
      .m-text-link {
        text-align: left;
      }
      .m-contact__box__item {
        .m-text-link {
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }

  .m-contact--col2 {
    display: flex;
    flex-wrap: wrap;
    .m-contact {
      width: calc((100% - 50px) / 2);
      margin-right: 50px;
      &:nth-child(2n) {
        margin-right: 0;
      }
      &:nth-child(n + 3) {
        margin-top: 50px;
      }
      &__ttl {
        font-size: 2.6rem;
      }
      &__box {
        margin-top: 25px;
        &__item {
          width: 100%;
          padding: 0;
          justify-content: center;
          &__tel,
          &__tel01,
          &__fax,
          &__free,
          &__navi {
            font-size: 3.2rem;
            a {
              padding-left: 57px;
              &::before {
                width: 42px;
                height: 28px;
              }
            }
          }
          &__tel01 {
            a {
              &::before {
                width: 48px;
                height: 29px;
              }
            }
          }
        }
      }
    }
    @include sp {
      .m-contact {
        width: 100%;
        margin-right: 0;
        &:nth-child(n + 3) {
          margin-top: 40px;
        }
        &:nth-child(n + 2) {
          margin-top: 40px;
        }
        &__ttl {
          font-size: 2.4rem;
        }
        &__box {
          &__item {
            &__tel,
            &__tel01,
            &__fax,
            &__free,
            &__navi {
              font-size: 2.4rem;
              a {
                padding-left: 38px;
                &::before {
                  width: 30px;
                  height: 20px;
                }
              }
            }
            &__tel01 {
              a {
                &::before {
                  width: 34px;
                  height: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  .m-ploducts-list {
    &__item {
      position: relative;
      display: flex;
      box-shadow: 0px 0px 35px 0px rgba(2, 34, 110, 0.12);
      &:nth-child(n + 2) {
        margin-top: 40px;
      }
      &__img {
        width: 270px;
        min-height: 270px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f9f9fc;
        img {
          width: 100%;
          object-fit: contain;
          max-height: 270px;
        }
      }
      &__detail {
        width: calc(100% - 270px);
        padding: 50px 40px;
        background: #fff;
        transition: $transition;
        position: relative;
      }
      &__link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      @include pc {
        &:hover {
          .m-ploducts-list {
            &__item {
              &__detail {
                background: #f4f4f8;
              }
            }
            &__ttl {
              color: $c-accent;
            }
          }
        }
      }
    }
    &__case {
      position: absolute;
      top: -25px;
      font-family: Oswald-Light;
      font-size: 2.2rem;
      &__inner {
        display: inline-block;
        background: $c-accent;
        background: -webkit-linear-gradient(
          0deg,
          rgb(1, 60, 136) 0%,
          rgb(0, 93, 136) 50%,
          rgb(1, 129, 140) 100%
        );
        background-clip: text;
        color: transparent;
      }
      &__num {
        font-size: 3.6rem;
      }
    }
    &__ttl {
      font-size: 2rem;
      font-weight: 500;
      line-height: 1.4;
      margin-bottom: 25px;
      transition: $transition;
      &.icon_new {
        text-indent: 50px;
        position: relative;
        &::before {
          position: absolute;
          content: "";
          top: 4px;
          left: 0px;
          width: 40px;
          height: 21px;
          background: url(/assets/img/common/icon_new.png) center/contain
            no-repeat;
        }
      }
    }
    &__subtxt {
      font-size: 1.5rem;
      line-height: 1.6;
      color: $c-sub;
      + * {
        margin-top: 20px;
      }
    }
    &__txt {
      font-size: 1.5rem;
      line-height: 2;
      &.line3 {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
    @include sp {
      &__item {
        display: block;
        &:nth-child(n + 2) {
          margin-top: 25px;
        }
        &__img {
          width: 100%;
          height: 170px;
          min-height: inherit;
          img {
            width: 100%;
            object-fit: contain;
            max-height: 170px;
          }
        }
        &__detail {
          width: 100%;
          padding: 25px 20px;
        }
      }
      &__case {
        position: inherit;
        top: inherit;
        font-size: 1.5rem;
        margin-bottom: 10px;
        &__num {
          font-size: 2.6rem;
        }
      }
      &__ttl {
        font-size: 1.7rem;
        margin-bottom: 15px;
      }
      &__txt {
        font-size: 1.6rem;
        &.line3 {
          -webkit-line-clamp: 4;
        }
      }
    }
  }

  .m-catalog {
    &__list {
      margin-top: 50px;
      display: flex;
      flex-wrap: wrap;
      &__item {
        width: calc((100% - 120px) / 4);
        margin-right: 40px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        &.no-link {
          pointer-events: none;
        }
        &:nth-child(4n) {
          margin-right: 0;
        }
        &:nth-child(n + 5) {
          margin-top: 50px;
        }
        &__img {
          position: relative;
          padding-top: 100%;
          width: 100%;
          &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(100, 108, 122, 0.1);
            transition: $transition;
            opacity: 0;
          }
          img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            object-fit: contain;
            width: 100%;
            height: 100%;
            margin: auto;
          }
        }
        &__txt {
          margin-top: 20px;
          font-size: 1.6rem;
          line-height: 1.6;
          font-weight: 500;
          width: 100%;
          transition: $transition;
          &.icon_new {
            position: relative;
            text-indent: 45px;
            &::before {
              position: absolute;
              content: "";
              top: 3px;
              left: 0px;
              width: 36px;
              height: 20px;
              background: url(/assets/img/common/icon_new.png) center/contain
                no-repeat;
            }
          }
          &.icon_newwindow {
            position: relative;
            &::after {
              content: "";
              position: absolute;
              width: 12px;
              height: 12px;
              bottom: 7px;
              margin-left: 5px;
              background: url(/assets/img/common/icon_newwindow.png)
                center/contain no-repeat;
            }
          }
        }
        &__subtxt {
          margin-top: 15px;
          font-size: 1.5rem;
          line-height: 1.6;
          color: $c-sub;
          width: 100%;
          + .m-catalog__list__item__txt {
            margin-top: 10px;
          }
          &.pdf {
            position: relative;
            padding-left: 23px;
            &::before {
              content: "";
              position: absolute;
              width: 18px;
              height: 17px;
              top: 3px;
              left: 0;
              background: url(/assets/img/common/icon_pdf.png) center/contain
                no-repeat;
            }
          }
          &.ebook {
            position: relative;
            padding-left: 23px;
            &::before {
              content: "";
              position: absolute;
              width: 18px;
              height: 18px;
              top: 3px;
              left: 0;
              background: url(/assets/img/common/icon_ebook.png) center/contain
                no-repeat;
            }
          }
        }
        &__link {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          &-2 {
            position: relative;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
          }
        }
        @include pc {
          &:hover {
            .m-catalog__list__item__img {
              &::before {
                opacity: 1;
              }
            }
            .m-catalog__list__item__txt {
              color: $c-accent;
            }
          }
        }
      }
      &--border {
        .m-catalog__list__item__img {
          width: 100%;
          max-height: 270px;
          text-align: center;
          img {
            height: 100%;
            width: auto;
            max-width: 100%;
            object-fit: contain;
            border: 1px solid #cccccc;
          }
        }
      }
    }
    @include sp {
      &__list {
        margin-top: 25px;
        &__item {
          width: calc((100% - 15px) / 2);
          margin-right: 15px;
          position: relative;
          &:nth-child(2n) {
            margin-right: 0;
          }
          &:nth-child(n + 5) {
            margin-top: 30px;
          }
          &:nth-child(n + 3) {
            margin-top: 30px;
          }
          &__txt {
            margin-top: 15px;
            font-size: 1.5rem;
          }
          &__img {
            transition: $transition;
          }
        }
        &--border {
          .m-catalog__list__item__img {
            width: 100%;
            max-height: 165px;
            text-align: center;
            img {
              height: 100%;
              width: auto;
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  .m-example-sentence {
    &__ttl {
      margin-top: 50px;
      font-size: 2rem;
      font-weight: 500;
    }
    &__en {
      position: relative;
      padding-left: 25px;
      margin-top: 20px;
      &::before {
        position: absolute;
        content: "●";
        font-size: 1.6rem;
        top: 0;
        left: 0;
      }
    }
    &__ja {
      position: relative;
      padding-left: 25px;
      margin-top: 15px;
      &::before {
        position: absolute;
        content: "○";
        font-size: 1.6rem;
        top: 0;
        left: 0;
      }
    }
    @include sp {
      &__ttl {
        margin-top: 30px;
        font-size: 1.7rem;
      }
      &__en {
        margin-top: 15px;
      }
    }
  }

  .m-form {
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
    &__required__text {
      font-size: 1.6rem;
      font-weight: 500;
      color: $c-red;
    }
    &__example {
      margin-top: 5px;
      font-size: 1.4rem;
      color: $c-sub;
    }
    &__box {
      &--flex,
      &--flex--sp {
        display: flex;
        .m-form__box__item {
          width: calc((100% - 30px) / 2);
          margin-right: 30px;
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }
      &--flex--confirm {
        display: flex;
        .m-form__box__item {
          &:nth-child(1) {
            width: 220px;
            margin-right: 30px;
          }
          &:nth-child(2) {
            width: calc(100% - 250px);
            margin-right: 0;
          }
        }
      }
      + .m-form__box {
        margin-top: 30px;
      }
    }
    label,
    .label {
      font-size: 1.6rem;
      font-weight: 500;
      margin-bottom: 5px;
      display: block;
    }
    .m-form--required {
      label,
      .label {
        position: relative;
        &::after {
          content: "＊";
          margin-left: 5px;
          font-size: 1.6rem;
          font-weight: 500;
          color: $c-red;
        }
      }
    }
    .m-form__selectbox {
      &__wrap {
        position: relative;
        select {
          height: 60px;
          width: 100%;
          padding: 0 20px;
          border-radius: 8px;
          background: #f5f6f9;
          border: 2px solid #d1d1d8;
          font-size: 1.6rem;
          outline-color: $c-accent;
          &.unselected {
            color: $c-sub;
          }
        }
        &::after {
          position: absolute;
          content: "";
          top: 50%;
          right: 35px;
          width: 15px;
          height: 10px;
          transform: translate(50%, -50%);
          background: url(/assets/img/common/icon_arrow_bottom.png)
            center/contain no-repeat;
          pointer-events: none;
        }
      }
    }
    .m-form__input {
      input {
        height: 60px;
        width: 100%;
        padding: 0 20px;
        border-radius: 8px;
        background: #f5f6f9;
        border: 2px solid #d1d1d8;
        font-size: 1.6rem;
        outline-color: $c-accent;
      }
    }
    .m-form__textarea {
      textarea {
        height: 200px;
        width: 100%;
        padding: 15px 20px;
        border-radius: 8px;
        background: #f5f6f9;
        border: 2px solid #d1d1d8;
        font-size: 1.6rem;
        outline-color: $c-accent;
      }
    }
    input[type="submit"] {
      position: relative;
      z-index: 1;
    }
    &__error {
      &__text {
        display: none;
        margin-top: 5px;
        font-size: 1.4rem;
        color: $c-red;
      }
      &.m-form__selectbox {
        .m-form__selectbox__wrap {
          select {
            background: #ffeeed;
            border: 2px solid #e60e00;
          }
        }
      }
      &.m-form__input {
        input {
          background: #ffeeed;
          border: 2px solid #e60e00;
        }
      }
      &.m-form__textarea {
        textarea {
          background: #ffeeed;
          border: 2px solid #e60e00;
        }
      }
      .m-form__error__text {
        display: block;
      }
    }
    @include sp {
      &__box {
        &--flex--sp {
          .m-form__box__item {
            width: calc((100% - 10px) / 2);
            margin-right: 10px;
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
        &--flex {
          flex-wrap: wrap;
          .m-form__box__item {
            width: 100%;
            margin-right: 0;
            &:nth-child(2n) {
              margin-top: 20px;
            }
          }
        }
        &--flex--confirm {
          .m-form__box__item {
            &:nth-child(1) {
              width: calc((100% - 10px) / 2);
              margin-right: 10px;
            }
            &:nth-child(2) {
              width: calc((100% - 10px) / 2);
              margin-right: 0;
            }
          }
        }
        + .m-form__box {
          margin-top: 20px;
        }
      }
      .m-form__selectbox {
        &__wrap {
          select {
            height: 50px;
            padding: 0 15px;
          }
          &::after {
            right: 25px;
          }
        }
      }
      .m-form__input {
        input {
          height: 50px;
          padding: 0 15px;
        }
      }
      .m-form__textarea {
        textarea {
          padding: 15px;
        }
      }
    }
  }

  // タイトル
  // タイトル直後に来る要素のマージンが効くようにモジュールの最後に記載しています。
  .m-h5 {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.4;
    margin-top: 50px;
    + * {
      margin-top: 25px;
    }
    @include sp {
      font-size: 1.7rem;
      margin-top: 35px;
      + * {
        margin-top: 15px;
      }
    }
  }

  .m-h4 {
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 1.4;
    margin-top: 60px;
    + * {
      margin-top: 30px;
    }
    @include sp {
      font-size: 1.8rem;
      margin-top: 40px;
      + * {
        margin-top: 20px;
      }
    }
  }

  .m-h3 {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 1.4;
    padding-left: 15px;
    border-left: 4px solid $c-accent;
    margin-top: 60px;
    + * {
      margin-top: 35px;
    }
    @include sp {
      font-size: 2rem;
      padding-left: 10px;
      margin-top: 40px;
      border-left: 3px solid $c-accent;
      + * {
        margin-top: 30px;
      }
    }
  }
  .m-h3--new {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 1.4;
    text-indent: 50px;
    margin-top: 60px;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      top: 8px;
      left: 0px;
      width: 40px;
      height: 21px;
      background: url(/assets/img/common/icon_new.png) center/contain no-repeat;
    }
    + * {
      margin-top: 30px;
    }
    @include sp {
      font-size: 2rem;
      margin-top: 40px;
      &::before {
        top: 4px;
      }
      + * {
        margin-top: 20px;
      }
    }
  }

  .m-h2 {
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1.4;
    border-top: 2px solid #e5e5e5;
    padding: 70px 0 0;
    position: relative;
    margin-top: 100px;
    &::before {
      position: absolute;
      content: "";
      display: block;
      top: -2px;
      left: 0;
      width: 200px;
      height: 4px;
      background: -webkit-linear-gradient(
        0deg,
        rgb(0, 45, 120) 0%,
        rgb(0, 86, 122) 50%,
        rgb(0, 130, 125) 100%
      );
    }
    + * {
      margin-top: 60px;
    }
    @include sp {
      font-size: 2.2rem;
      padding: 40px 0 0;
      margin-top: 60px;
      &::before {
        width: 100px;
      }
      + * {
        margin-top: 30px;
      }
    }
  }

  .m-h1 {
    font-size: 4rem;
    font-weight: 500;
    @include sp {
      font-size: 2.4rem;
    }
  }

  // ギャラリーパーツ
  .m-gallery {
    &__inner {
      max-width: 800px;
      margin: 0 auto;
    }
    &__main {
      text-align: center;
      img {
        width: 100%;
        height: auto;
        max-height: 600px;
        object-fit: contain;
      }
      iframe{
        width: 100%;
        height: auto;
        aspect-ratio: 16 / 9;
      }
      &.nano{
        position: relative;
        width: 100%;
        height: 450px;
        @include sp{
          height: 220px;
        }
        iframe,img{
          position: absolute;
          top: 0;
          bottom: 0;
          left:0;
          right: 0;
          margin: auto;
          max-width: 100%;
          max-height: 100%;

        }
      }
    }
    &__sub {
      display: flex;
      flex-wrap: wrap;
      &__item {
        width: calc((100% - 45px) / 4);
        margin-top: 15px;
        margin-right: 15px;
        cursor: pointer;
        transition: $transition;
        &:nth-child(4n) {
          margin-right: 0;
        }
        &:hover {
          opacity: 0.75;
        }
        @include sp {
          width: calc((100% - 30px) / 3);
          &:nth-child(4n) {
            margin-right: 15px;
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
      &__image {
        background: #f5f6f9;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 100%;
        position: relative;
        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          object-fit: contain;
          width: 100%;
          height: 100%;
          padding: 10px;
        }
        iframe{
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          object-fit: contain;
          width: 200%;
          height: auto;
          padding: 10px;
          margin: auto;
          aspect-ratio: 16 / 9;
          scale: 0.5;
          transform: translate(-50%, 0);
          pointer-events: none;
        }
        @include sp {
          min-height: 100px;
          img {
            padding: 5px;
          }
        }
      }
      &__text {
        font-size: 1.6rem;
        @include sp {
          font-size: 1.4rem;
        }
      }
    }
  }

  // 特別なタイトル
  .m-supecial-ttl {
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1.6;
    @include sp {
      font-size: 2.4rem;
    }
  }

  .m-imagelayout {
    &__text {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      &__item {
        width: calc((100% - 20px) / 2);
        border: 2px solid $c-accent;
        border-radius: 10px;
        padding: 20px;
        &:first-child {
          margin-right: 20px;
        }
        &:last-child {
          margin-top: 20px;
          border: 2px solid $c-red;
        }
      }
    }
    @include sp {
      &__text {
        &__item {
          width: calc((100% - 10px) / 2);
          padding: 10px;
          &:first-child {
            margin-right: 10px;
          }
          &:last-child {
            margin-top: 10px;
            width: 80%;
          }
          .m-text--normal {
            &:nth-child(n + 2) {
              font-size: 1.4rem;
              line-height: 1.6;
            }
          }
        }
      } 
    }
  }
  .m-08-nanopercolator__table {
    .m-table__data {
      img {
        max-height: 80px;
        width: auto;
        object-fit: contain;
      }
    }
  }
  .m-21-06-sampleholder-adapter {
    &__width400 {
      max-width: 400px;
    }
    .m-image-text {
      &__image {
        width: 180px;
        margin: 0 auto;
      }
      @include sp {
        flex-direction: row;
        &__item {
          width: calc(30% - 10px);
          &:nth-child(2n) {
            width: 70%;
            margin-top: 0;
            margin-left: 10px;
          }
        }
        &__image {
          width: 100%;
        }
      }
    }
  }
}

.m-pager {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  &.-hide {
    display: none;
  }
  &__prev,
  &__next {
    &.disabled {
      a {
        pointer-events: none;
        opacity: 0.6;
      }
    }
    a {
      width: 40px;
      height: 40px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 9px;
        background: url(/assets/img/common/pagenation_arrow_left.png)
          center/contain no-repeat;
      }
    }
  }
  &__prev {
    a {
      &::before {
        background: url(/assets/img/common/pagenation_arrow_left.png)
          center/contain no-repeat;
      }
    }
  }
  &__next {
    a {
      &::before {
        background: url(/assets/img/common/pagenation_arrow_right.png)
          center/contain no-repeat;
      }
    }
  }
  &__list {
    display: flex;
    li {
      margin: 0 5px;
      a {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        background: #f4f4f8;
        transition: $transition;
        font-family: Oswald-Medium;
        &.active {
          color: #fff;
          background: #606e85;
          pointer-events: none;
        }
        &:hover {
          color: #fff;
          background: #606e85;
        }
        &.dot {
          pointer-events: none;
          background: transparent;
          font-size: 1.2rem;
          font-weight: bold;
          letter-spacing: -1.8rem;
          width: 15px;
        }
      }
    }
  }
  @include sp {
    &__prev,
    &__next {
      a {
        width: 30px;
      }
    }
  }
}

.m-production {
  &_end {
    margin-bottom: 30px;
    padding: 30px;
    background-color: #e2ebf3;
    border-radius: 6px;
  }
}