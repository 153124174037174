@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

.p-products_detail {
    overflow: hidden;
    .l-lower__header {
        padding-bottom: 30px;
        position: relative;
        &::before {
            position: absolute;
            content: "";
            top: -120%;
            left: 50%;
            right: 0;
            bottom: 0;
            width: 300%;
            height: 200%;
            transform: translate(-50%, 0) rotate(-15deg);
            background: -webkit-linear-gradient( 0deg, rgb(231,234,242) 1%, rgb(241,244,249) 100%);
        }
        .l-main__inner {
            position: relative;
            z-index: 1;
        }
        .l-lower__content {
            position: relative;
            z-index: 1;
        }
        @include sp {
            padding: 0 0 40px;
        }
    }
    .m-imagetext__img {
        background: #fff;
    }
    .p-products_detail {
        &__mainv {
            z-index: 1;
            position: relative;
            background: #fff;

            + .l-breadcrumb {
                position: relative;
                z-index: 1;
                top: initial;
            }
        }
        &__header {
            display: flex;
            flex-wrap: wrap;
            &__txtbox {
                width: calc(100% - 500px);
                margin-right: 50px;
                position: relative;
                align-self: flex-start;
            }
            &__img {
                width: 450px;
            }
            &__ttl {
                font-size: 3.6rem;
                line-height: 1.4;
                font-weight: 500;
                &__gradient {
                    background: $c-accent;
                    background: -webkit-linear-gradient( 45deg, rgb(1,60,136) 0%, rgb(0,93,136) 50%, rgb(1,129,140) 100%);
                    background-clip: text;
                    color: transparent;
                }
                &.icon_new {
                    position: relative;
                    text-indent: 72px;
                    &::before {
                        position: absolute;
                        content: "";
                        top: 13px;
                        left: 0px;
                        width: 52px;
                        height: 28px;
                        background: url(/assets/img/common/icon_new.png) center/contain no-repeat;
                    }
                }
            }
            &__tag {
                margin-top: 15px;
                border: 1px solid #666666;
                font-size: 1.3rem;
                line-height: 1.75;
                padding: 3px 10px 4px;
                display: inline-block;
            }
            &__note {
                font-size: 1.6rem;
                line-height: 1.5;
                margin-top: 15px;
                color: #e60e00;
            }
            @include sp {
                &__txtbox {
                    width: 100%;
                    margin-right: 0;
                    order: 2;
                }
                &__img {
                    width: 100%;
                    order: 1;
                }
                &__ttl {
                    font-size: 2.6rem;
                    &.icon_new {
                        text-indent: 50px;
                        &::before {
                            top: 8px;
                            width: 40px;
                            height: 21px;
                        }
                    }
                }
                &__tag {
                    line-height: 1;
                    margin-bottom: 10px;
                }
                &__note {
                    font-size: 1.4rem;
                    margin-top: 5px
                }
            }
        }
    
        &__nav {
            position: absolute;
            z-index: 10;
            width: 250px;
            background: #fff;
            padding: 30px;
            border-radius: 20px;
            box-shadow: 0px 0px 35px 0px rgba(2, 34, 110, 0.12);
            &.is-fixed {
                position: fixed;
                &.is-fixed-end {
                    position: absolute;
                }
            }
            &__item {
                margin-top: 15px;
                &__link {
                    color: #7a7a7a;
                    font-size: 1.5rem;
                    transition: $transition;
                    &:hover {
                        color: $c-accent;
                    }
                    &.current {
                        color: $c-base;
                        font-weight: 500;
                        padding-left: 20px;
                        position: relative;
                        &::before {
                            position: absolute;
                            content: '';
                            left: 0;
                            top: 50%;
                            transform: translate(0, -50%);
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            background: $c-accent;
                        } 
                    }
                }
            }
            &__contact {
                margin-top: 20px;
                &__link {
                    height: 85px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    overflow: hidden;
                    position: relative;
                    &::before, &::after {
                        position: absolute;
                        content: "";
                        display: block;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 0;
                        transition: $transition;
                    }
                    &::before {
                        background: -webkit-linear-gradient(75deg, rgb(1, 60, 136) 0%, rgb(0, 93, 136) 50%, rgb(1, 129, 140) 100%);
                    }
                    &::after {
                        background: -webkit-linear-gradient(75deg, rgb(1, 129, 140) 0%, rgb(0, 93, 136) 50%, rgb(1, 60, 136) 100%);
                        opacity: 0;
                    }
                    &:hover {
                        &::after {
                            opacity: 1;
                        }
                    }
                }
                &__img {
                    position: relative;
                    width: 24px;
                    height: 18px;
                    z-index: 1;
                }
                &__txt {
                    margin-top: 15px;
                    position: relative;
                    color: #fff;
                    font-size: 1.4rem;
                    font-weight: 500;
                    z-index: 1;
                }
            }
            @include pcmin1000 {
                margin-top: 40px;
                position: sticky;
                border-radius: 6px;
                padding: 0;
                overflow: hidden;
                width: 100%;
                &__inner {
                    overflow: auto;
                    -ms-overflow-style: none;    /* IE, Edge 対応 */
                    scrollbar-width: none;       /* Firefox 対応 */
                    &::-webkit-scrollbar {  /* Chrome, Safari 対応 */
                        display:none;
                    }    
                }
                &.is-fixed {
                    position: fixed;
                    margin-top: 10px;
                    left: 30px;
                    right: 30px;
                    width: auto;
                    &.is-fixed-end {
                        position: absolute;
                    }
                }
                &::before, &::after {
                    position: absolute;
                    content: '';
                    width: 60px;
                    height: 45px;
                    top: 0;
                    bottom: 0;
                    z-index: 1;
                }
                &::before {
                    left: 0;
                    background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
                }
                &::after {
                    right: 0;
                    background: linear-gradient(270deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);    
                }
                &__list {
                    display: flex;
                    justify-content: center;
                    padding: 0 80px;
                    min-width: 900px;   
                }
                
                &__item {
                    margin-top: 0;
                    &__link {
                        padding: 12px 10px;
                        display: block;
                        font-size: 1.4rem;
                        &.current {
                            padding: 12px 10px;
                            &::before {
                                display: none;
                            } 
                        }
                    }
                }
                &__contact {
                    display: none;
                }
            }
            @include sp {        
                order: 3;
                margin-top: 30px;
                position: sticky;
                border-radius: 6px;
                padding: 0;
                overflow: hidden;
                width: 100%;
                &__inner {
                    overflow: auto;
                    -ms-overflow-style: none;    /* IE, Edge 対応 */
                    scrollbar-width: none;       /* Firefox 対応 */
                    &::-webkit-scrollbar {  /* Chrome, Safari 対応 */
                        display:none;
                    }    
                }
                &__list {
                    display: flex;
                    justify-content: center;
                    padding: 0;
                    min-width: 740px; 
                }
                &.is-fixed {
                    position: fixed;
                    margin-top: 10px;
                    &.is-fixed-end {
                        position: absolute;
                    }
                }
                &::before, &::after {
                    position: absolute;
                    content: '';
                    width: 30px;
                    height: 45px;
                    top: 0;
                    bottom: 0;
                    z-index: 1;
                }
                &::before {
                    left: 0;
                    background: linear-gradient(90deg, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0.2) 100%);
                }
                &::after {
                    right: 0;
                    background: linear-gradient(270deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);    
                }
                &__item {
                    margin-top: 0;
                    &__link {
                        padding: 12px 10px;
                        display: block;
                        font-size: 1.4rem;
                        &.current {
                            padding: 12px 10px;
                            &::before {
                                display: none;
                            } 
                        }
                    }
                }
                &__contact {
                    display: none;
                }
            }
        }
        &__main {
            width: calc(100% - 300px);
            position: relative;
            margin-left: auto;
            @include pcmin1000 {
                width: 100%;
            }
            @include sp {
                width: 100%;
                z-index: 0;
            }
        }
        &__icon {
            &__note {
                display: block;
                margin-top: 15px;
                line-height: 1.75;
                &__inner {
                    color: #fff;
                    border: 1px solid #e60e00;
                    background-color: #e60e00;
                    font-size: 1.3rem;
                    padding: 3px 10px 4px;
                    display: inline-block;
                }
            }
            @include sp {
                &__note {
                    line-height: 1;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .l-products__banner {
        margin-top: 60px;
        padding: 0;
        @include pc {
            &__item--en {
                .ttl {
                    font-size: 2.4rem;
                }
                .txt {
                    font-size: 1.4rem;
                }
            }
        }
        @include pcmin1200 {
            &__item--en {
                .ttl {
                    font-size: vwm(21px);
                }
                .txt {
                    margin-top: vwm(20px);
                    font-size: vwm(14px);
                }
            }
        }
        @include pcmin1000 {
            &__item--en {
                .ttl {
                    font-size: vwm(28px);
                }
                .txt {
                    margin-top: vwm(20px);
                    font-size: vwm(15px);
                }
            }
        }
    }
    .m-btn--products {
        width: 80%;
        margin-right: 0;
        margin-left: auto;
    }
}