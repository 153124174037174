@use "sass:math";
@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

// 製品情報遷移リンク
.l-products__link {
    background: #f4f4f8;
    padding: 70px 0;
    &__inner {
        max-width: 1600px;
        padding: 0 6%;
        margin: 0 auto;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        background: #fff;
        border-radius: 20px;
        box-shadow: 0 0 35px rgba(2, 34, 110, 0.12);
        overflow: hidden;
    }
    &__item {
        width: calc(100% / 5);
        position: relative;
        padding: 30px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: $transition;
        &:last-child {
            .l-products__link__item__inner {
                border-right: none;
            }
        }
        &__inner {
            width: 100%;
            border-right: 1px solid #e9e9f1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            &__content {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
        }
        .icon {
            width: 65px;
            height: 65px;
            margin-bottom: 15px;
        }
        .txt {
            font-size: 1.8rem;
            line-height: 1.6;
            text-align: center;
            font-weight: 500;
            transition: $transition;
           
        }
        .link {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        @include pc {
            &:hover {
                background: #f9f9fb;
                .l-products__link__item__inner {
                    .txt {
                        color: $c-accent;
                    }
                }
            }
        }
        
    }
    @include pcmin1200 {
        padding: vwm(70px) 0;
        &__list {
            border-radius: vwm(20px);
        }
        &__item {
            padding: vwm(30px) 0;
            .icon {
                width: vwm(65px);
                height: vwm(65px);
            }
            .txt {
                font-size: vwm(18px);
            }
        }
    }
    
    @include sp {
        padding: 50px 0;
        &__inner {
            padding: 0 15px;
        }
        &__list {
            padding: 0 20px;
        }
        &__item {
            width: calc(100% / 2);
            border-bottom: 1px solid #e9e9f1;
            align-items: flex-start;
            &:nth-child(2n) {
                .l-products__link__item__inner {
                    border-right: none;
                }
            }
            &:last-child {
                border-bottom: none;
                .l-products__link__item__inner {
                    border-right: 1px solid #e9e9f1;
                }
            }
            &__inner {
                &__content {
                    flex-direction: column;
                }
            }
            .icon {
                width: 60px;
                height: 60px;
                margin-right: 0;
                margin-bottom: 10px;
            }
            .txt {
                font-size: 1.3rem;
            }
        }
    }
    // 製品トップ用
    &.l-products__link__list--top {
        .l-products__link {
            &__item {
                width: calc(100% / 3);
                &:last-child {
                    .l-products__link__item__inner {
                        border-right: none;
                    }
                }
                &__inner {
                    &__content {
                        flex-direction: row;
                    }
                }
                .icon {
                    width: 75px;
                    height: 75px;
                    margin-right: 20px;
                    img:nth-child(2) {
                        display: none;
                    }
                }
                .txt {
                    &--sub {
                        display: none;
                        font-size: 1.2rem;
                        color: #666666;
                        margin-top: 5px;
                        position: absolute;
                        bottom: 20px;
                        br {
                            display: none;
                        }
                    }
                }
                &.disabled {
                    padding: 30px 0 45px;
                    pointer-events: none;
                    .icon {
                        img:nth-child(1) {
                            display: none;
                        }
                        img:nth-child(2) {
                            display: block;
                        }
                    }
                    .txt {
                        color: #c5c5c5;
                        &--sub {
                            display: block;
                        }
                    }
                }
            }
            @include pcmin1200 {
                &__item {
                    .icon {
                        margin-right: vwm(20px);
                    }
                    .txt {
                        &--sub {
                            font-size: vwm(12px);
                            margin-top: vwm(5px);
                            bottom: vwm(20px);
                        }
                    }
                    &.disabled {
                        padding: vwm(30px) 0 vwm(45px);
                        .txt {
                            &--sub {
                                display: block;
                            }
                        }
                    }
                }
            }
            @include pcmin1000 {
                &__item {
                    .txt {
                        &--sub {
                            bottom: vwm(5px);
                            br {
                                display: block;
                            }
                        }
                    }
                }
            }
            @include sp {
                &__list {
                    padding: 0;
                }
                &__item {
                    width: calc(100% / 3);
                    border-right: 1px solid #e9e9f1;
                    padding: 15px 10px 20px;
                    &:last-child {
                        border-right: 0;
                    }
                    &__inner {
                        border: none;
                        &__content {
                            flex-direction: column;
                        }
                    }
                    .icon {
                        width: 60px;
                        height: 60px;
                        margin-right: 0;
                        margin-bottom: 5px;
                    }
                    .txt {
                        &--sub {
                            margin-top: 5px;
                            br {
                                display: block;
                            }
                        }
                    }
                    &.disabled {
                        padding: 30px 0 60px;
                    }
                }
            }
        }
    }
    // 用語集なしVer
    &.l-products__link__list--col4 {
        .l-products__link {
            &__item {
                width: calc(100% / 3);
            }
            @include sp {
                &__item {
                    width: 100%;
                    &:last-child .l-products__link__item__inner {
                        border: none;
                    }
                }
            }
        }
    }
}

.l-products__tech {
    padding: 80px 0;
    background: url(/assets/img/products/tech_bg.png) center/cover no-repeat;
    .l-main__inner {
        display: flex;
        justify-content: flex-end;
    }
    &__inner {
        background: #fff;
        padding: 45px 70px;
        max-width: 580px;
        width: 50%;
    }
    &__txt {
        margin-top: 30px;
        font-size: 1.6rem;
        line-height: 2;
    }
    @include pcmin1200 {
        &__inner {
            padding: vwm(45px) vwm(70px);
        }
    }
    @include sp {
        padding: 220px 15px 60px;
        background: url(/assets/img/products/tech_bg_sp.png) top/cover no-repeat;
        &__inner {
            padding: 40px 25px;
            width: 100%;
            margin: 0 auto;
        }
    }
}
// バナー
.l-products__banner {
    padding: 105px 0 135px;
    background: #fff;
    &__item {
        border: 1px solid #E2E2E2;
        height: 250px;
        background: url(/assets/img/products/bnr.jpg) center/cover no-repeat;
        box-shadow: 0 0 35px rgba(2, 34, 110, 0.12);
        position: relative;
        transition: $transition;
        &__inner {
            width: 46.7%;
            height: 250px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .ttl {
            color: $c-accent;
            font-family: "NotoSansJP","Yu Gothic","Yu Gothic Medium","游ゴシック Medium",YuGothic,"游ゴシック体","ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        }
        .txt {
            font-family: "NotoSansJP","Yu Gothic","Yu Gothic Medium","游ゴシック Medium",YuGothic,"游ゴシック体","ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        }
        .link {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            cursor: pointer;
        }
        &:hover {
            opacity: 0.8;
            box-shadow: none;
        }
        &:nth-child(n + 2) {
            margin-top: 50px;
        }
    }
    &__item--ja {
        .ttl {
            font-size: 4.2rem;
            font-weight: 300;
            letter-spacing: 2rem;
        }
        .txt {
            margin-top: 20px;
            font-size: 1.5rem;
            line-height: 1.8;
            color: $c-accent;
            letter-spacing: 0.4rem;
            text-align: center;
        }
    }
    &__item--en {
        .ttl {
            font-size: 2.8rem;
            font-weight: 400;
        }
        .txt {
            margin-top: 20px;
            font-size: 1.5rem;
            line-height: 1.8;
            color: $c-accent;
            text-align: center;
        }
    }
    .m-btn--center {
        margin-top: 50px;
        display: none;
    }
    @include pcmin1000 {
        &__item--ja {
            .ttl {
                font-size: 3.8rem;
                letter-spacing: 1rem;
            }
            .txt {
                font-size: 1.2rem;
                letter-spacing: 0.3rem;
            }
        }
        &__item--en {
            .ttl {
                font-size: vwm(28px);
            }
            .txt {
                margin-top: vwm(20px);
                font-size: vwm(15px);
            }
        }
    }
    @include sp {
        padding: 50px 0 90px;
        &__item {
            max-width: 345px;
            height: 290px;
            margin: 0 auto;
            background: url(/assets/img/products/bnr_sp.jpg) center/cover no-repeat;
            &__inner {
                width: 100%;
                height: 125px;
                position: absolute;
                bottom: 0;
            }
            &:nth-child(n + 2) {
                margin-top: 30px;
            }
        }
        &__item--ja {
            .ttl {
                font-size: 2.2rem;
                letter-spacing: 0.5rem;
            }
            .txt {
                margin-top: 10px;
                font-size: 1.2rem;
                line-height: 1.6;
                letter-spacing: 0.1rem;
            }
        }
        &__item--en {
            .ttl {
                font-size: 1.9rem;
            }
            .txt {
                margin-top: 10px;
                font-size: 1.3rem;
            }
        }
    }
}
// お問い合せ
.l-products__contact {
    padding: 80px;
    background: -webkit-linear-gradient( -180deg, rgb(87,197,207) 0%, rgb(73,169,214) 24%, rgb(58,140,220) 50%, rgb(49,118,206) 79%, rgb(39,96,191) 100%);
    .l-main__inner {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__txtbox {
        font-family: "NotoSansJP","Yu Gothic","Yu Gothic Medium","游ゴシック Medium",YuGothic,"游ゴシック体","ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        .ttl {
            font-size: 3.6rem;
            font-weight: 500;
            color: #fff;
        }
        .txt {
            margin-top: 30px;
            font-size: 1.6rem;
            color: #fff;
            line-height: 2;
        }
    }
    &__btn__wrap {
        margin-left: 60px;
        .btn {
            width: 350px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            border-radius: 35px;
            overflow: hidden;
            cursor: pointer;
            font-family: "NotoSansJP","Yu Gothic","Yu Gothic Medium","游ゴシック Medium",YuGothic,"游ゴシック体","ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
            &::before, &::after {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                transition: $transition;
            }
            &::before {
                background: -webkit-linear-gradient( 75deg, rgb(11,72,150) 0%, rgb(0,93,136) 50%, rgb(1,129,140) 100%);
                opacity: 1;
            }
            &::after {
                background: -webkit-linear-gradient( 75deg, rgb(1,129,140) 0%, rgb(0,93,136) 50%, rgb(11,72,150) 100%);
                opacity: 0;
            }
            &:hover {
                &::after {
                    opacity: 1;
                }
            }
            .icon__mail {
                position: relative;
                z-index: 1;
                color: #fff;
                font-size: 2rem;
                font-weight: 500;
                display: inline-block;
                padding-left: 38px;
                &::before {
                    position: absolute;
                    content: '';
                    width: 24px;
                    height: 18px;
                    left: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    background: url(/assets/img/products/icon_mail.png) center/contain no-repeat;
                }
            }
        }
    }
    @include pcmin1000 {
        padding: 50px 0 60px;
        .l-main__inner {
            display: block;
        }
        &__txtbox {
            .ttl {
                font-size: 2.4rem;
                text-align: center;
            }
            .txt {
                margin-top: 25px;
                font-size: 1.5rem;
                text-align: center;
            }
        }
        &__btn__wrap {
            margin-top: 25px;
            margin-left: 0;
            .btn {
                width: 290px;
                height: 64px;
                margin: 0 auto;
                .icon__mail {
                    font-size: 1.8rem;
                    padding-left: 38px;
                }
            }
        }
    }
    @include sp {
        padding: 50px 0 60px;
        .l-main__inner {
            display: block;
        }
        &__txtbox {
            .ttl {
                font-size: 2.4rem;
                text-align: center;
            }
            .txt {
                margin-top: 25px;
                font-size: 1.5rem;
            }
        }
        &__btn__wrap {
            margin-top: 25px;
            margin-left: 0;
            .btn {
                width: 290px;
                height: 64px;
                margin: 0 auto;
                .icon__mail {
                    font-size: 1.8rem;
                    padding-left: 38px;
                }
            }
        }
    }
}