@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// トップコミットメントトップ
.p-top-commitment {
  &__sec01 {
    .m-sec__ttl__color {
      @include pc {
        font-size: 5.2rem;
      }

      @include sp {
        font-size: 4.2rem;
      }
    }

    .m-sec__ttl__sub {
      @include sp {
        margin-top: 15px;
      }
    }

    &__catchcopy {
      font-family: "NotoSerifJP";
      letter-spacing: 0.04em;
      line-height: 2;

      @include pc {
        font-size: clamp(1.8rem, 2.3vw, 3.2rem);
        margin-top: 45px;
      }

      @include sp {
        font-size: 2.1rem;
        margin-top: 35px;
      }

      + p {
        @include pc {
          margin-top: 40px;
        }

        @include sp {
          margin-top: 20px;
        }
      }
    }

    .p-top-commitment__sec01__txt {
      line-height: 2.2;
    }

    .m-image-text--type01 {
      .m-image-text__item {
        @include pc {
          width: 40%;
        }

        &:nth-child(2n) {
          @include pc {
            width: 54.2%;
            margin-right: 0;
          }

          @include sp {
            margin-top: 0;
          }
        }
      }

      .m-image-text__image {
        background-color: transparent;
        position: relative;

        @include pc {
          top: -60px;
        }

        @include pcmin1000 {
          top: -10px;
        }

        @include sp {
          margin-top: 40px;
        }
      }
    }

    .m-image {
      &.m-image--col1 {
        .m-image__item {
          margin-left: 0;
        }
      }
    }
  }

  &__sign {
    .m-image__item {
      .m-image__image {
        margin-left: 0;

        @include pc {
          width: 43%;
        }

        @include sp {
          width: 180px;
        }
      }
    }
  }
}
