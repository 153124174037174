@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

.p-newsletter {
    .elq-form {
        padding: 20px 6% 60px;
        background: #f5f6f9;
    }
    .elq-form .elq-required {
        color: #e60e00;
        font-size: 1.6rem;
        font-weight: 500;
    }
    .elq-form .elq-label {
        display: inline-block;
        margin-bottom: 5px;
        pointer-events: none;
        font-size: 1.6rem;
        font-weight: 500;
    }
    .elq-form .form-element-layout {
        margin-top: 30px;
    }
    .elq-form .elq-item-input {
        height: 60px;
        width: 100%;
        padding: 0 20px;
        border-radius: 8px;
        background: #fff;
        border: 2px solid #d1d1d8;
        font-size: 1.6rem;
        outline-color: #002d78;
    }
    .elq-form .elq-item-select {
        height: 60px;
        width: 100%;
        padding: 0 20px;
        border-radius: 8px;
        background: #f5f6f9;
        border: 2px solid #d1d1d8;
        font-size: 1.6rem;
        outline-color: #002d78;
    }
    .elq-form .elq-field-style input[type=submit] {
        min-width: 290px;
        margin: 0 auto;
        min-height: 56px;
        padding: 14px 20px;
        border: 2px solid #dfdfe8;
        border-radius: 60px;
        font-size: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        transition: all .2s linear;
        text-align: center;
        cursor: pointer;
    }
    .elq-form .elq-field-style input[type=submit]:hover {
        background: #dfdfe8;
    }
    .elq-form .LV_invalid {
        color: #e60e00;
        font-size: 1.4rem;
        font-weight: 500;
    }
    .elq-form .LV_valid {
        display: none;
    }
    .LV_invalid_field, .elq-item-input.LV_invalid_field:active, .elq-item-input.LV_invalid_field:hover, textarea.LV_invalid_field:active, textarea.LV_invalid_field:hover {
        outline-color:#e60e00 !important;
        border: 2px solid #e60e00 !important;
    }
    .elq-form .container-fluid {
        display: flex;
        flex-wrap: wrap;
        > .row {
            .single-checkbox-row {
                .elq-item-label {
                    background: #fff;
                    width: 100%;
                    height: 70px;
                    display: flex;
                    align-items: center;
                    padding: 0 20px;
                    padding-left: 47px;
                    border-radius: 8px;
                    border: 2px solid #d1d1d8;
                    position: relative;
                    &::before, &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 15px;
                        transform: translate(0, -50%);
                        transition: $transition;
                    }
                    &::before {
                        width: 22px;
                        height: 22px;
                        background: #fff;
                        border: 2px solid #ccc;
                        border-radius: 2px;
                    }
                    &::after {
                        width: 10px;
                        height: 8px;
                        left: 20px;
                        background: url(/assets/img/common/icon_check.png) center/contain no-repeat;
                        opacity: 0;
                    }
                }
                input[type=checkbox]:checked~label {
                    background: #e5f2fd;
                    &::before {
                        background: #002fa8;
                    }
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }
   
    @media (max-width:767px){
        .elq-form {
            padding: 10px 20px 40px;
        }
        .elq-form .form-element-layout {
            margin-top: 20px;   
        }
        .elq-form .elq-item-input {
            height: 50px;
            padding: 0 15px;
        }
        .elq-form .elq-item-select {
            height: 50px;
            padding: 0 15px;
        }
        .elq-form .elq-field-style input[type=submit] {
            width: 100%;
        }
        .elq-form .container-fluid {
            > .row {
                .single-checkbox-row {
                    .elq-item-label {
                        height: 50px;
                    }
                }
            }
        }
    }

    // 登録
    &.p-newsletter-subscribe {
        .elq-form .container-fluid {
            > .row {
                &:first-of-type {
                    width: 100%;
                }
                &:nth-of-type(2) {
                    margin-right: 30px;
                }
                &:nth-of-type(2), &:nth-of-type(3) {
                    width: calc((100% - 30px) / 2);
                }
                &:nth-of-type(n + 5) {
                    width: calc((100% - 30px) / 3);
                    margin-right: 15px;
                    margin-top: 50px;
                    .form-element-layout {
                        margin-top: 0;
                    }
                }
                &:nth-of-type(7), &:nth-of-type(10) {
                    margin-right: 0;
                }
                &:nth-of-type(n + 8) {
                    margin-top: 15px;
                }
                &:nth-of-type(11) {
                    display: none;
                }
                &:last-of-type {
                    margin-top: 40px;
                    width: 100%;
                }
            }
        }
        @media (max-width:767px){
            .elq-form .container-fluid {
                > .row {
                    &:first-of-type {
                        width: 100%;
                    }
                    &:nth-of-type(2) {
                        margin-right: 0;
                    }
                    &:nth-of-type(2), &:nth-of-type(3) {
                        width: 100%;
                    }
                    &:nth-of-type(5) {
                        margin-top: 40px;
                    }
                    &:nth-of-type(n + 5) {
                        width: 100%;
                        margin-right: 0;
                        .form-element-layout {
                            margin-top: 0;
                        }
                    }
                    &:nth-of-type(n + 6) {
                        margin-top: 10px;
                    }
                    &:last-of-type {
                        margin-top: 40px;
                    }
                }
            }
        }
    } 

    // 解除
    &.p-newsletter-unsubscribe {
        .elq-form .container-fluid {
            > .row {
                &:first-of-type {
                    width: 100%;
                }
                &:nth-of-type(2) {
                    display: none;
                }
                &:nth-of-type(n + 2) {
                    width: calc((100% - 30px) / 3);
                    margin-right: 15px;
                    margin-top: 50px;
                    .form-element-layout {
                        margin-top: 0;
                    }
                }
                &:nth-of-type(5), &:nth-of-type(8) {
                    margin-right: 0;
                }
                &:nth-of-type(n + 6) {
                    margin-top: 15px;
                }
                &:last-of-type {
                    margin-top: 40px;
                    width: 100%;
                }
            }
        }
        @media (max-width:767px){
            .elq-form .container-fluid {
                > .row {
                    &:first-of-type {
                        width: 100%;
                    }
                    &:nth-of-type(n + 2) {
                        width: 100%;
                        margin-right: 0;
                        margin-top: 10px;
                        .form-element-layout {
                            margin-top: 0;
                        }
                    }
                    &:nth-of-type(3) {
                        margin-top: 40px;
                    }
                    &:nth-of-type(n + 6) {
                        margin-top: 10px;
                    }
                    &:last-of-type {
                        margin-top: 40px;
                        width: 100%;
                    }
                }
            }
        }
    } 
}