@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

.p-corporate {
    .l-lower__mainv {
        min-height: 500px;
        max-height: 600px;
        &__img {
            img {
                min-height: 500px;
                max-height: 600px;
            }
        }
        @include sp {
            min-height: inherit;
            max-height: 400px;
            img {
                min-height: inherit;
                max-height: 400px;
            }
        }
    }
    &__lead {
        position: relative;
        z-index: 1;
        &__img {
            img {
                width:100%;
                min-height: 375px;
                max-height: 460px;
                object-fit:cover;
            }
        }
        &__inner {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translate(0, -50%);
        }
        &__ttl {
            font-size: 4.6rem;
            color: $c-accent;
            font-weight: 500;
            text-align: center;
            margin-bottom: 50px;
        }
        @include sp {
            &__ttl {
                font-size: 2.8rem;
                margin-bottom: 30px;
            }
        }
    }
    .m-sec__ttl {
        margin-bottom: 50px;
        &__color {
            font-size: 5.2rem;
        }
        &__sub {
            font-size: 1.6rem;
        }
        @include sp {
            margin-bottom: 40px;
            &__color {
                font-size: 4.2rem;
            }
        }
    }
    .m-btn--arrow {
        text-align: left;
    }
    &__otherinfo {
        border-top: 1px solid #eeeeee;
        padding-top: 70px;
        @include sp {
            padding-top: 50px;
        }
        &__flex {
            margin-top: 50px;
            display: flex;
            &__item {
                &:first-child {
                    width: calc((100% - 80px) / 3 * 2);
                    margin-right: 40px;
                }
                &:last-child {
                    width: calc((100% - 80px) / 3)
                }
            }
            @include sp {
                margin-top: 20px;
                flex-wrap: wrap;
                &__item {
                    &:first-child {
                        width: 100%;
                        margin-right: 0px;
                    }
                    &:last-child {
                        width: 100%;
                    }
                }
            }
        }
        &__list {
            display: flex;
            flex-wrap: wrap;
            &__item {
                width: calc((100% - 40px) / 2);
                margin-right: 40px;
                &:nth-child(2n) {
                    margin-right: 0;
                }
                &:nth-child(n + 3) {
                    margin-top: 20px;
                }
            }
            &__link {
                font-size: 1.5rem;
                padding: 18px 35px 18px 0;
                display: block;
                position: relative;
                border-bottom: 1px solid #cccccc;
                transition: $transition;
                &:before, &::after {
                    position: absolute;
                    content: '';
                    top: 50%;
                    transform: translate(0, -50%);
                }
                &::before {
                    right: 0;
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    background: #fff;
                    border: 1px solid #002d78;
                    transform: translate(0, -50%);
                }
                &::after {
                    position: absolute;
                    content: "";
                    background: url(/assets/img/common/icon_arrow_right.png) center/contain no-repeat;
                    width: 8px;
                    height: 11px;
                    transition: $transition;
                    right: 9px;
                }
                &:hover {
                    color: $c-accent;
                    &::after {
                        right: 5px;
                    }
                }
            }
            @include sp {
                &__item {
                    width: 100%;
                    margin-right: 0;
                    &:nth-child(n + 2) {
                        margin-top: 0;
                    }
                    &:nth-child(n + 3) {
                        margin-top: 0;
                    }
                }
            }
        }
        &__image {
            &__link {
                display: block;
                box-shadow: 0px 0px 20px 0px rgba(2, 34, 110, 0.1);
                transition: $transition;
                img {
                    transition: $transition;
                }
                &:hover {
                    box-shadow: none;
                    img {
                        opacity: 0.75;
                    }
                }
            }
            @include sp {
                margin-top: 40px;
            }
        }
    }
    &__numbers {
        display: flex;
        flex-wrap: wrap;
        &__item {
            background: #fff;
            padding: 30px;
            width: calc((100% - 40px) / 3);
            margin-right: 20px;
            &:nth-child(3n) {
                margin-right: 0;
            }
            &:nth-child(n + 4) {
                margin-top: 20px;
            }
        }
        &__image {
            width: 160px;
            margin: 0 auto;
        }
        &__textbox {
            margin-top: 20px;
        }
        &__text {
            font-size: 1.8rem;
            font-weight: 500;
            text-align: center;
        }
        &__num {
            margin-top: 10px;
            text-align: center;
            font-size: 2.6rem;
            font-weight: 500;
            line-height: 1.2;
            &__inner, &__main {
                background: $c-accent;
                background: -webkit-linear-gradient( 45deg, rgb(1,60,136) 0%, rgb(0,93,136) 50%, rgb(1,129,140) 100%);
                background-clip: text;
                color: transparent;
                font-feature-settings : inherit;
            }
            &__main {
                font-family: Oswald-Regular;
                font-size: 5.5rem;
                margin-right: 5px;
            }
        }
        @include pcmin1200 {
            &__image {
                width: vwm(160px);
            }
            &__textbox {
                margin-top: vwm(20px);
            }
            &__text {
                font-size: vwm(18px);
            }
            &__num {
                font-size: vwm(26px);
                &__main {
                    font-size: vwm(55px);
                }
            }
        }
        @include sp {
            &__item {
                background: #fff;
                padding: 25px 10px;
                width: calc((100% - 10px) / 2);
                margin-right: 10px;
                &:nth-child(3n) {
                    margin-right: 10px;
                }
                &:nth-child(2n) {
                    margin-right: 0;
                }
                &:nth-child(n + 3), &:nth-child(n + 4) {
                    margin-top: 10px;
                }
            }
            &__image {
                width: 100px;
            }
            &__text {
                font-size: 1.5rem;
            }
            &__num {
                font-size: 1.8rem;
                &__main {
                    font-size: 4rem;
                }
            }
        }
    }
    &__nature {
        margin-top: 30px;
        .m-text--big {
            font-weight: 500;
            padding-left: 42px;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0, -50%);
                width: 32px;
                height: 29px;
                background: #f4f4f8 url(/assets/img/corporate/icon_paper.png) center/contain no-repeat;
            }
        }
    }
    &__topmessage {
        padding: 0;
        // background: #f4f4f8 url(/assets/img/corporate/topmessage_bg.png) center/contain no-repeat;
        background: #f4f4f8;
        position: relative;
        &__bg {
            max-width: 1400px;
            margin: 0 auto;   
        }
        .l-main__inner {
            top: 50%;
            transform: translate(0, -50%);
            position: absolute;
            left: 60%;
        }
        @include pcmin1000 {
            .m-sec__ttl__color {
                font-size: vwm(52px);
            }
        }
        @include sp {
            background: #fff;
            &__bg {
                height: 495px;
                background: #f4f4f8 url(/assets/img/corporate/topmessage_bg_sp.jpg) center/cover no-repeat;
                position: relative;
            }
            .m-sec__ttl {
                position: absolute;
                top: 40px;
                left: 50%;
                transform: translate(-50%, 0);
                text-align: center;
                width: 100%;
            }
            .l-main__inner {
                position: inherit;
                top: inherit;
                left: inherit;
                transform: inherit;
            }
            &__textbox {
                margin-top: 20px;
            }
            .m-btn--arrow {
                text-align: right;
            }
        }
    }
    &__aboutjeol {
        &__list {
            display: flex;
            flex-wrap: wrap;
            max-width: 1600px;
            margin: 0 auto;
            &__item {
                width: calc(100% / 3);
                position: relative;
                @include pc {
                    &:hover {
                        .p-corporate__aboutjeol__image {
                            img {
                                transform: scale(1.1);
                            }
                        }   
                    }
                }
            }
        }
        &__image {
            overflow: hidden;
            img {
                transition: $transition;
            }
        }
        &__text {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 35px;
            font-size: 2.2rem;
            color: #fff;
            font-weight: 500;
            text-align: center;
        }
        &__link {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        @include sp {
            &__list {
                &__item {
                    width: 100%;
                    &:nth-child(n + 2) {
                        margin-top: 1px;
                    }
                }
            }
            &__text {
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translate(0, -50%);
                right: inherit;
                bottom: inherit;
                font-size: 1.8rem;
                text-align: left;
            }
        }
    }
}
