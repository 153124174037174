@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// イベントセミナートップ
.p-events_seminar {
    overflow: hidden;
    &__sec {
        background-image: -webkit-linear-gradient( 90deg, rgb(230,230,236) 0%, rgb(244,244,248) 100%);
        .l-main__inner {
            display: flex;
            justify-content: center;
            padding: 0;
        }
        &__image {
            width: 60%;
            position: relative;
            img {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: auto;
            }
        }
        &__inner {
            width: 40%;
            padding: 100px 30px 100px 40px;
            position: relative;
            &::before {
                position: absolute;
                content: '';
                top: 0;
                right: calc(100% - 1px);
                width: 150px;
                height: 100.1%;
                background-image: -webkit-linear-gradient( 90deg, rgb(230,230,236) 0%, rgb(244,244,248) 100%);
                clip-path: polygon(100% 0, 0% 100%, 100% 100%);
            }
        }
        &--reverse {
            .p-events_seminar__sec {
                &__image {  
                    img {
                        left: 0;
                    }          
                }
                &__inner {
                    padding: 100px 40px 100px 30px;
                    &::before {
                        right: inherit;
                        left: calc(100% - 1px);
                        clip-path: polygon(0% 0, 0% 100%, 100% 0%);
                    }
                }
            }
            .m-btn--arrow {
                text-align: left;
                &:nth-child(n + 2) {
                    margin-top: 40px;
                }
            }
        }
        @include sp {
            background: #e8e8ed;
            .l-main__inner {
                flex-direction: column;
            }
            &__image {
                order: 2;            
                width: 100%;
                height: 340px;
                background: url(/assets/img/events_seminars/events_seminar_01_sp.png) center/cover no-repeat;
                img {
                    display: none;
                }
            }
            &__inner {
                order: 1;
                width: 100%;
                padding: 50px 15px 30px 15px;
                position: relative;
                &::before {
                    position: absolute;
                    content: '';
                    top: calc(100% - 1px);
                    right: 0;
                    width: 100%;
                    height: 70px;
                    background: #e8e8ed;
                    clip-path: polygon(100% 0%, 0% 0%, 0 100%);
                    z-index: 1;
                }
            }
            &--reverse {
                .p-events_seminar__sec {
                    &__image {            
                        background: url(/assets/img/events_seminars/events_seminar_02_sp.png) center/cover no-repeat;
                    }
                    &__inner {
                        padding: 50px 15px 30px 15px;
                        &::before {
                            right: inherit;
                            left: 0;
                            clip-path: polygon(100% 0%, 0% 0%, 0 100%);
                        }
                    }
                }
                .m-btn--arrow {
                    text-align: right;
                    &:nth-child(n + 2) {
                        margin-top: 30px;
                    }
                }
            }
        }
    }   
}

// 開催予定・開催中のセミナー / ウェビナー
.p-events_seminar-lower {
    &__news {
        &__item {
            position: relative;
            border-bottom: 1px solid #cccccc;
            padding: 30px 0;
            .subinfo {
                display: flex;
                flex-wrap: wrap;
                .date {
                    font-size: 1.8rem;
                    font-weight: 500;
                    min-width: 100px;
                    display: inline-block;
                    margin-right: 30px;
                }
                .tag {
                    width: 140px;
                    padding: 4px 0;
                    font-size: 1.3rem;
                    line-height: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &--seminar-webinar {
                        border: 1px solid #4E84E9;
                        color: #4E84E9;
                    }
                    &--seminar {
                        border: 1px solid #F39800;
                        color: #F39800;
                    }
                    &--webinar {
                        border: 1px solid #8FC31F;
                        color: #8FC31F;
                    }
                }
                .full {
                    font-size: 1.3rem;
                    color: #fff;
                    margin-left: 10px;
                    background-image: -webkit-linear-gradient( -180deg, rgb(87,197,207) 0%, rgb(73,169,214) 24%, rgb(58,140,220) 50%, rgb(49,118,206) 79%, rgb(39,96,191) 100%);
                    width: 100px;
                    line-height: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;                   
                }
                
            }
            .txt {
                margin-top: 20px;
                font-size: 1.8rem;
                &__link {
                    @include link-underline {
                      &::before {
                        background: $c-accent;
                      }
                    }
                }
            }
            .product-name {
                font-size: 1.4rem;
                color: #444444;
                line-height: 1.6;
                margin-top: 15px;
                padding-left: 32px;
                position: relative;
                &::before {
                    position: absolute;
                    content: "";
                    top: 1px;
                    left: 0;
                    background: url(/assets/img/common/icon_product.png) center/contain no-repeat;
                    width: 20px;
                    height: 20px;
                }
            }
            .link {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
            @include pc {
                &:hover {
                    .txt {
                        color: $c-accent;
                        &__link {
                          &::before {
                            transform: scaleX(1);
                          }
                        }
                    }
                }
            }
        }
        @include sp {
            &__item {
                padding: 25px 0;
                .subinfo {
                    display: flex;
                    .date {
                        font-size: 1.6rem;
                        width: 100%;
                        margin-bottom: 10px;
                    }
                    .tag {
                        width: 130px;
                        font-size: 1.2rem;
                    }
                    .full {
                        font-size: 1.2rem;
                    }
                }
                .txt {
                    margin-top: 15px;
                    font-size: 1.6rem;
                }
                .product-name {
                    font-size: 1.3rem;
                }
            }
        }
    }
}

// 開催予定・開催中のイベント / 展示会
.p-events-lower {
    &__eventslist {
        &__item {
            padding: 30px 0;
            border-bottom: 1px solid #cccccc;
            position: relative;
            &:first-child {
                padding-top: 0;
            }
            @include pc {
                &:hover {
                    .p-events-lower__eventslist__text {
                        color: $c-accent;
                        &__link {
                          &::before {
                            transform: scaleX(1);
                          }
                        }
                    }
                }
            }
        }
        &__link {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &__date {
            font-size: 1.8rem;
            font-weight: 500;
            line-height: 1.3;
        }
        &__text {
            font-size: 1.8rem;
            line-height: 1.6;
            margin-top: 20px;
            &__link {
                @include link-underline {
                  &::before {
                    background: $c-accent;
                  }
                }
            } 
        }
        &__place {
            font-size: 1.4rem;
            color: #444444;
            margin-top: 15px;
            padding-left: 28px;
            position: relative;
            &::before {
                position: absolute;
                content: '';
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                background: url(/assets/img/common/icon_access.png) center/contain no-repeat;
                width: 16px;
                height: 20px;
            }
        }
        @include sp {
            &__item {
                padding: 25px 0;
            }
            &__date {
                font-size: 1.6rem;
            }
            &__text {
                font-size: 1.6rem;
                margin-top: 15px;    
            }
            &__place {
                font-size: 1.3rem;
            }
        }
    }
}

// イベント詳細ページ
.p-events-detail {
    &__topinfo {
        &__text--normal {
            font-size: 1.6rem;
            line-height: 2;
            text-align: right;
            &:nth-child(n + 2) {
                margin-top: 10px;
            }
        }
    }
}