@use "../../foundation/mixin" as *;
@use "../../foundation/color" as *;
@use "../../foundation/variable" as *;
@use "../../foundation/function" as *;

// アプリケーションノート
.p-applications {
    &__news {
        &__item {
            position: relative;
            padding: 30px 0;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            border-bottom: 1px solid #ccc;
            cursor: pointer;
            .txtbox {
                width: calc(100% - 100px);
                .ttl {
                    font-size: 1.8rem;
                    margin-bottom: 10px;
                }
                .tag {
                    font-size: 1.3rem;
                    color: #fff;
                    padding: 5px 13px;
                    background: #009fc1;
                    display: inline-block;
                    line-height: 1;
                    margin-right: 15px;
                }
                .txt {
                    display: inline-block;
                    font-size: 1.4rem;
                    color: #444444;
                }
            }
            .date {
                letter-spacing: 0.05rem;
                font-size: 1.4rem;
                color: #444444;
                width: 100px;
            }
            .link {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
        @include sp {
            &__item {
                padding: 25px 0;
                align-items: flex-start;
                .txtbox {
                    width: 100%;
                    .ttl {
                        font-size: 1.6rem;
                    }
                }
                .date {
                    margin-top: 10px;
                    width: 100%;
                }
            }
        }
    }
    .m-btn--center {
        margin-top: 50px;
        @include sp {
            margin-top: 40px;
        }
    }
    .m-tab {
        @include sp {
            .m-bg {
                padding-bottom: 5px;   
            }
        }
    }
}