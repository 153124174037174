@use "../foundation/mixin" as *;
@use "../foundation/color" as *;
@use "../foundation/variable" as *;
@use "../foundation/function" as *;

/* 背景色
***************** */
.m-bg__wrap {
  background: #f4f4f8;
  padding: 70px 0 90px;
  @include sp {
    padding: 50px 0 60px;
  }
}
.m-bg {
  background: $c-bg;
}

/* タブ
***************** */
.m-tab {
  margin-top: 80px;
  position: relative;
  &__index {
    position: absolute;
    top: calc(65px / -2);
    left: 30px;
    right: 30px;
    max-width: 1540px;
    margin: 0 auto;
    display: flex;
    box-shadow: 0px 0px 35px 0px rgba(2, 34, 110, 0.12);
    border-radius: 10px;
    &__item {
      height: 65px;
      background: #fff;
      position: relative;
      z-index: 2;
      cursor: pointer;
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
      transition: $transition;
      padding: 17px 0;
      font-size: 1.8rem;
      color: #888888;
      font-weight: 500;
      letter-spacing: 0.05rem;
      text-align: center;
      &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #dddddd;
        height: 100%;
        pointer-events: none;
      }
      .strong {
        font-size: 2.2rem;
      }
      &::before {
        opacity: 0;
        position: absolute;
        content: "";
        top: 100%;
        left: 50%;
        background: -webkit-linear-gradient(
          -180deg,
          rgb(87, 197, 207) 0%,
          rgb(73, 169, 214) 24%,
          rgb(58, 140, 220) 50%,
          rgb(49, 118, 206) 79%,
          rgb(39, 96, 191) 100%
        );
        transform: translate(-50%, 0);
        width: 20px;
        height: 10px;
        clip-path: polygon(50% 100%, 100% 0%, 0% 0%);
      }
      &:first-child {
        border-radius: 10px 0 0 10px;
        border-left: 1px solid #dddddd;
        .m-tab__index__item__inner {
          border-left: 0;
        }
      }
      &:last-child {
        border-radius: 0 10px 10px 0;
        border-right: 1px solid #dddddd;
      }
      &:hover {
        background: #f4f4f8;
      }
      &.active {
        color: #fff;
        pointer-events: none;
        background: -webkit-linear-gradient(
          -180deg,
          rgb(87, 197, 207) 0%,
          rgb(73, 169, 214) 24%,
          rgb(58, 140, 220) 50%,
          rgb(49, 118, 206) 79%,
          rgb(39, 96, 191) 100%
        );
        border: none;
        .m-tab__index__item__inner {
          border: none;
        }
        + .m-tab__index__item {
          .m-tab__index__item__inner {
            border: none;
          }
        }
        &::before {
          opacity: 1;
        }
      }
    }
    &--col3 {
      .m-tab__index__item {
        width: calc(100% / 3);
      }
    }
    &--col2 {
      &.m-tab__index {
        max-width: 800px;
      }
      .m-tab__index__item {
        max-width: 400px;
        width: calc(100% / 2);
      }
    }
  }
  &__container {
    position: relative;
    z-index: 1;
    display: none;
    &.active {
      display: block;
    }
    &__inner {
      > * {
        padding: 32.5px 0 0;
      }
    }
  }
  .m-btn--refresh {
    margin-top: 20px;
  }
  @include sp {
    margin-top: 30px;
    &__index {
      top: 0;
      left: 0px;
      right: 0px;
      border-radius: 0;
      &__item {
        height: 80px;
        padding: 10px 0;
        font-size: 1.4rem;
        .strong {
          font-size: 1.8rem;
          display: block;
          line-height: 1.2;
        }
        &::before {
          width: 14px;
          height: 8px;
        }
        &:first-child {
          border-radius: 0;
        }
        &:last-child {
          border-radius: 0;
        }
      }
    }
    &__container {
      &__inner {
        > * {
          padding: 80px 0 0;
        }
      }
    }
    .m-btn--filter {
      margin: 35px 0;
    }
  }
}

.m-search__result {
  border-top: 1px solid #dddddd;
  background: #eff0f5;
  padding: 30px 0 40px;
  @include sp {
    padding: 25px 0 30px;
  }
  &__txt {
    text-align: center;
    font-size: 2rem;
    color: $c-accent;
    font-weight: bold;
    font-family: Oswald-Medium;
    @include sp {
      font-size: 1.6rem;
    }
  }
  &__num {
    font-size: 3rem;
    color: $c-accent;
    font-weight: bold;
    @include sp {
      font-size: 2.4rem;
    }
  }
  &__detail {
    margin-top: 70px;
  }
  &__detail--mt45 {
    margin-top: 45px;
    @include sp {
      margin-top: 25px;
    }
  }
  &__detail--mt70-50 {
    margin-top: 70px;
    @include sp {
      margin-top: 50px;
    }
  }
  &__detail--noresult {
    margin-top: 70px;
    .txt {
      font-size: 1.8rem;
      text-align: center;
    }
    .search {
      margin-top: 30px;
      .m-search__keywords__input {
        padding: 5px 15px;
      }
      .m-btn--center {
        margin-top: 30px;
      }
    }
    @include sp {
      margin-top: 40px;
      .txt {
        font-size: 1.6rem;
      }
      .search {
        margin-top: 25px;
        .m-btn--center {
          margin-top: 25px;
        }
      }
    }
  }
}

.m-checklist {
  margin-top: 20px;
  padding-bottom: 10px;
  &__item {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 15px;
    &__input {
      &:checked + label {
        background: #606e85;
        color: #fff;
      }
    }
    &__label {
      display: inline-block;
      background: #fff;
      padding: 8px 20px;
      border-radius: 19px;
      transition: $transition;
      &.disabled {
        pointer-events: none;
        background: #e0e0e4;
        color: #999999;
      }
    }
  }
  &__ttl {
    font-size: 1.7rem;
    font-weight: bold;
    width: 190px;
    padding-bottom: 10px;
  }
  &__subttl {
    margin-bottom: 5px;
    font-size: 1.3rem;
    color: #5a6880;
    font-weight: 500;
  }
  &__checkflex {
    display: flex;
    margin-top: 20px;
    &:nth-of-type(n + 2) {
      padding-top: 25px;
    }
    &--center {
      justify-content: center;
      .m-checklist {
        &__ttl {
          padding-right: 20px;
          padding-bottom: 0;
          margin-bottom: 15px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        &__checkflex__item {
          width: auto;
        }
      }
    }
    &__item {
      width: calc(100% - 190px);
      &__inner {
        display: flex;
        flex-wrap: wrap;
        .m-checklist {
          &:first-child {
            margin-right: 15px;
          }
        }
      }
    }
    .m-checklist {
      margin: 0;
    }
  }
  @include pcmin1200 {
    &__ttl {
      width: 100%;
    }
    &__subttl {
      margin-bottom: 5px;
      font-size: 1.3rem;
      color: #5a6880;
      font-weight: 500;
    }
    &__checkflex {
      display: block;
      &__item {
        width: 100%;
      }
      &--center {
        display: flex;
        justify-content: center;
        .m-checklist {
          &__ttl {
            width: 190px;
            padding-right: 20px;
            padding-bottom: 0;
            margin-bottom: 15px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
          &__checkflex__item {
            width: auto;
          }
        }
      }
    }
  }
  @include sp {
    &__ttl {
      font-size: 1.6rem;
      width: 100%;
      border-bottom: 1px solid #cccccc;
    }
    &__subttl {
      font-size: 1.4rem;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 0;
    }
    &__checkflex {
      display: block;
      margin-top: 30px;
      &__item {
        width: 100%;
      }
    }
  }
}

.m-search__keywords {
  &__wrap {
    padding: 50px 0 70px;
    @include sp {
      padding: 35px 0;
    }
  }
  max-width: 600px;
  height: 54px;
  font-size: 1.6rem;
  margin: 0 auto;
  position: relative;
  &__input {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 6px;
    border: 2px solid #d1d1d8;
    padding: 5px 54px 5px 15px;
    outline: none;
    letter-spacing: 0.15rem;
  }
  &__submit {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 0;
    width: 54px;
    height: 100%;
    margin-left: 5px;
    &__item {
      width: 100%;
      height: 100%;
      border-radius: 0 6px 6px 0;
    }
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      right: 50%;
      width: 22px;
      height: 22px;
      -webkit-transform: translate(50%, -50%);
      transform: translate(50%, -50%);
      background: url(/assets/img/common/icon_search.png) center/contain
        no-repeat;
      pointer-events: none;
      transition: $transition;
    }
  }
}

.m-search__selectbox {
  &__wrap {
    padding: 35px 0;
  }
  position: relative;
  height: 54px;
  &__select {
    background: #fff;
    border: 2px solid #d1d1d8;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    padding: 5px 54px 5px 15px;
    color: #aaaaaa;
    outline: none;
  }
  &::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 10px;
    background: url(/assets/img/common/icon_arrow_bottom.png) center/contain
      no-repeat;
    right: 20px;
    top: 50%;
    transform: translate(0px, -50%);
    pointer-events: none;
  }
}

.m-search__initials {
  padding: 40px 0 25px;
  &__ttlbox {
    width: 190px;
    background: #a7b0c1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 40px;
  }
  &__listbox {
    width: calc(100% - 230px);
  }
  &__ja,
  &__en {
    display: flex;
    .ttl {
      color: #fff;
      font-weight: 500;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      &__item {
        margin-right: 10px;
        .link {
          width: 100%;
          height: 42px;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 2rem;
          font-weight: 500;
          background: #fff;
          border: 1px solid #dddddd;
          &:hover {
            background: #f4f4f8;
            color: $c-accent;
            transition: $transition;
          }
          &.active {
            color: #fff;
            background: #606e85;
          }
        }
      }
    }
  }
  &__ja {
    .ttl {
      font-size: 1.8rem;
      .strong {
        font-size: 2.8rem;
      }
    }
    .m-search__initials__listbox {
      display: flex;
      .list {
        width: calc(((100% - 130px) / 14) * 5 + 40px);
        &:last-child {
          margin-left: calc((100% - 130px) / 14 + 20px);
        }
      }
    }
    .list {
      &__item {
        width: calc((100% - 40px) / 5);
        &:nth-child(5n) {
          margin-right: 0;
        }
        &:nth-child(n + 6) {
          margin-top: 10px;
        }
        &.dummy {
          visibility: none;
          opacity: 0;
        }
      }
    }
  }
  &__en {
    margin-top: 30px;
    .ttl {
      font-size: 2rem;
    }
    .list {
      &__item {
        width: calc((100% - 130px) / 14);
        &:nth-child(14n) {
          margin-right: 0;
        }
        &:nth-child(n + 15) {
          margin-top: 10px;
        }
        &:last-child {
          margin-right: 0;
          width: calc(((100% - 130px) / 14) * 2 + 10px);
        }
      }
    }
  }
  @include pcmin1200 {
    &__ttlbox {
      width: 100%;
      background: none;
      margin-right: 0;
      justify-content: flex-start;
    }
    &__listbox {
      width: 100%;
    }
    &__ja,
    &__en {
      display: flex;
      flex-wrap: wrap;
      .ttl {
        color: $c-base;
        margin-bottom: 10px;
      }
      .list {
        &__item {
          margin-right: 7px;
        }
      }
    }
    &__ja {
      .ttl {
        font-size: 1.7rem;
        .strong {
          font-size: 1.7rem;
        }
      }
      .m-search__initials__listbox {
        .list {
          width: calc(((100% - 77px) / 11) * 5 + 28px);
          &:last-child {
            margin-left: calc((100% - 77px) / 11 + 14px);
          }
        }
      }
      .list {
        &__item {
          width: calc((100% - 28px) / 5);
          &:nth-child(5n) {
            margin-right: 0;
          }
          &:nth-child(n + 6) {
            margin-top: 7px;
          }
        }
      }
    }
    &__en {
      margin-top: 30px;
      .ttl {
        font-size: 1.7rem;
        br {
          display: none;
        }
      }
      .list {
        &__item {
          width: calc((100% - 77px) / 11);
          &:nth-child(14n) {
            margin-right: 7px;
          }
          &:nth-child(11n) {
            margin-right: 0;
          }
          &:nth-child(n + 12) {
            margin-top: 7px;
          }
          &:last-child {
            margin-right: 0;
            width: calc(((100% - 77px) / 11) * 2 + 7px);
          }
        }
      }
    }
  }
}

.m-modal--filter {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  background: #fff;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  &.active {
    opacity: 1;
    visibility: visible;
  }
  &__close--icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    padding: 20px;
    z-index: 5;
    cursor: pointer;
    img {
      vertical-align: inherit;
    }
  }
  &__ttl {
    height: 60px;
    background: #efeff3;
    padding: 0 60px 0 15px;
    line-height: 60px;
    font-weight: 500;
    font-size: 1.4rem;
  }
  .m-checklist {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    &__item {
      width: calc(50% - 5px);
      margin: 0;
      margin-right: 10px;
      border-bottom: 1px solid #cccccc;
      &:nth-child(2n) {
        margin-right: 0;
      }
      &__label {
        position: relative;
        padding: 20px;
        padding-left: 30px;
        display: block;
        font-size: 1.4rem;
        border-radius: 0;
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transition: all 0.2s linear;
        }
        &::before {
          left: 0;
          width: 20px;
          height: 20px;
          background: #fff;
          border: 2px solid #ccc;
          border-radius: 2px;
          transform: translateY(-50%);
        }
        &::after {
          transform: translateY(-50%);
          left: 4px;
          width: 12px;
          height: 10px;
          background: url(/assets/img/common/icon_check.png) center/contain
            no-repeat;
          opacity: 0;
        }
        &.disabled {
          color: #ccc;
          background: inherit;
          &::before {
            opacity: 0.6;
          }
        }
      }
      &__input {
        &:checked + label {
          background: none;
          color: $c-base;
          &::before {
            background: #002fa8;
            border: 2px solid #002fa8;
          }
          &::after {
            opacity: 1;
          }
        }
      }
    }
    &--subttl {
      .m-checklist__item {
        width: calc(50% - 5px);
        margin: 0;
        margin-right: 10px;
        &:nth-child(2n + 1) {
          margin-right: 0;
        }
      }
    }
  }
  .m-btn--center {
    margin: 25px 0 35px;
  }
  .m-search__result {
    padding: 30px 0 50px;
    background: #efeff3;
    border-top: 0;
  }
  .m-btn--sure {
    margin-top: 20px;
  }
}

/* ニュースリスト
***************** */
.m-news {
  &--border {
    border-top: 1px solid #cccccc;
  }
  .m-news__item {
    position: relative;
    padding: 30px 0;
    display: flex;
    border-bottom: 1px solid #cccccc;
    cursor: pointer;
    &::before {
      display: none;
    }
    &:nth-child(n + 2) {
      margin-top: 0;
    }
    @include pc {
      &:hover {
        .txt {
          color: $c-accent;
          &__link {
            &::before {
              transform: scaleX(1);
            }
          }
          .icon__newwindow {
            &::after {
              background: url(/assets/img/common/icon_newwindow_hover.png)
                center/contain no-repeat;
            }
          }
        }
      }
    }
    .date {
      font-size: 1.5rem;
      color: $c-sub;
      width: 120px;
    }
    .txt {
      width: calc(100% - 120px);
      font-size: 1.6rem;
      &--sub {
        color: $c-sub;
        margin-left: 5px;
      }
      .icon__zip, .icon__pdf, .icon__xls, .icon__doc  {
        display: inline-block;
        .txt--sub {
          &::before {
            display: inline-block;
            content: "";
            width: 18px;
            height: 17px;
            margin-right: 5px;
            margin-bottom: -3px;
          }
        }
      }
      .icon__zip {
        .txt--sub {
          &::before {
            background: url(/assets/img/common/icon_zip.png) center/contain no-repeat;
          }
        }
      }
      .icon__pdf {
        .txt--sub {
          &::before {
            background: url(/assets/img/common/icon_pdf.png) center/contain no-repeat;
          }
        }
      }
      .icon__xls {
        .txt--sub {
          &::before {
            background: url(/assets/img/common/icon_xls.png) center/contain no-repeat;
          }
        }
      }
      .icon__doc {
        .txt--sub {
          &::before {
            background: url(/assets/img/common/icon_doc.png) center/contain no-repeat;
          }
        }
      }
      .icon__newwindow {
        display: inline-block;
        &::after {
          display: inline-block;
          content: "";
          width: 13px;
          height: 12px;
          margin-left: 5px;
          background: url(/assets/img/common/icon_newwindow.png) center/contain
            no-repeat;
          transition: $transition;
        }
      }
      &__link {
        @include link-underline {
          &::before {
            background: $c-accent;
          }
        }
      }
    }
    .link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  @include sp {
    .m-news__item {
      padding: 25px 0;
      flex-wrap: wrap;
      .date {
        font-size: 1.5rem;
      }
      .txt {
        margin-top: 15px;
        width: 100%;
      }
    }
  }
}
.m-newslist {
  &.-hide {
    display: none;
  }
  &__item {
    position: relative;
    padding: 30px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cccccc;
    @include pc {
      &:hover {
        .txt {
          color: $c-accent;
          &__link {
            &::before {
              transform: scaleX(1);
            }
          }
          .icon__newwindow {
            &::after {
              background: url(/assets/img/common/icon_newwindow_hover.png)
                center/contain no-repeat;
            }
          }
        }
      }
    }
    .date {
      font-size: 1.5rem;
      color: $c-sub;
      width: 95px;
    }
    .tag {
      width: 150px;
      font-size: 1.3rem;
      text-align: center;
      margin: 0 30px;
      background-color: #fff;
      &--support {
        color: #f0991d;
        border: 1px solid #f0991d;
      }
      &--pr {
        color: #0c4fb7;
        border: 1px solid #0c4fb7;
      }
      &--applications {
        color: #17aeb4;
        border: 1px solid #17aeb4;
      }
      &--ir {
        color: #c28a04;
        border: 1px solid #c28a04;
      }
      &--info {
        color: #03807b;
        border: 1px solid #03807b;
      }
      &--events_seminars {
        color: #ec574c;
        border: 1px solid #ec574c;
      }
      &--products {
        color: #002d78;
        border: 1px solid #002d78;
      }
      &--seminars_webinars {
        border: 1px solid #4e84e9;
        color: #4e84e9;
      }
      &--seminars {
        border: 1px solid #f39800;
        color: #f39800;
      }
      &--webinars {
        border: 1px solid #8fc31f;
        color: #8fc31f;
      }
      &--events {
        border: 1px solid #8c2eab;
        color: #8c2eab;
      }
    }
    .txt {
      width: calc(100% - 280px);
      font-size: 1.6rem;
      &__link {
        @include link-underline {
          &::before {
            background: $c-accent;
          }
        }
      }
      &--sub {
        color: $c-sub;
        margin-left: 5px;
      }
      .icon__zip, .icon__pdf, .icon__xls, .icon__doc, .icon__ebook  {
        display: inline-block;
        .txt--sub {
          &::before {
            display: inline-block;
            content: "";
            width: 18px;
            height: 17px;
            margin-right: 5px;
            margin-bottom: -3px;
          }
        }
      }
      .icon__zip {
        .txt--sub {
          &::before {
            background: url(/assets/img/common/icon_zip.png) center/contain no-repeat;
          }
        }
      }
      .icon__pdf {
        .txt--sub {
          &::before {
            background: url(/assets/img/common/icon_pdf.png) center/contain no-repeat;
          }
        }
      }
      .icon__xls {
        .txt--sub {
          &::before {
            background: url(/assets/img/common/icon_xls.png) center/contain no-repeat;
          }
        }
      }
      .icon__doc {
        .txt--sub {
          &::before {
            background: url(/assets/img/common/icon_doc.png) center/contain no-repeat;
          }
        }
      }
      .icon__newwindow {
        display: inline-block;
        &::after {
          display: inline-block;
          content: "";
          width: 13px;
          height: 12px;
          margin-left: 5px;
          background: url(/assets/img/common/icon_newwindow.png) center/contain no-repeat;
          transition: $transition;
        }
      }
      .icon__ebook {
        .txt--sub {
          &::before {
            background: url(/assets/img/common/icon_ebook.png) center/contain no-repeat;
          }
        }
      }
    }
    &__link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  @include sp {
    &__item {
      padding: 25px 0;
      flex-wrap: wrap;
      .date {
        font-size: 1.5rem;
      }
      .tag {
        width: 120px;
        font-size: 1.2rem;
        margin: 0 10px;
      }
      .txt {
        margin-top: 15px;
        width: 100%;
      }
    }
  }
}

.m-movie {
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  &__inner {
    position: relative;
    width: 100%;
    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 56.25%;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  @include sp {
    max-width: inherit;
  }
}

.m-movie--small {
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
  &__inner {
    position: relative;
    width: 100%;
    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 56.25%;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  @include sp {
    max-width: inherit;
  }
}

.m-movie--100per {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  &__inner {
    position: relative;
    width: 100%;
    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 56.25%;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  @include sp {
    max-width: inherit;
  }
}

.m-movie--mp4 {
  &__inner {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    video {
      width: 100%;
    }
  }
  @include sp {
    &__inner {
      max-width: inherit;
    }
  }
}
.m-link-banner {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  &__item {
      width: calc((100% - 80px) / 3);
      margin-right: 40px;
      &:nth-child(3n) {
          margin-right: 0;
      }
      &:nth-child(n + 4) {
          margin-top: 20px;
      }
  }
  &__link {
      font-size: 1.8rem;
      padding: 18px 35px 18px 0;
      display: block;
      position: relative;
      border-bottom: 1px solid #cccccc;
      transition: $transition;
      &:before, &::after {
          position: absolute;
          content: '';
          top: 50%;
          transform: translate(0, -50%);
      }
      &::before {
          right: 0;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background: #fff;
          border: 1px solid #002d78;
          transform: translate(0, -50%);
      }
      &::after {
          position: absolute;
          content: "";
          background: url(/assets/img/common/icon_arrow_right.png) center/contain no-repeat;
          width: 8px;
          height: 11px;
          transition: $transition;
          right: 9px;
      }
      &:hover {
          color: $c-accent;
          &::after {
              right: 5px;
          }
      }
  }
  @include sp {
    margin-top: 30px;
    &__item {
        width: 100%;
        margin-right: 0;
        &:nth-child(n + 2) {
            margin-top: 0;
        }
        &:nth-child(n + 3) {
            margin-top: 0;
        }
    }
  }
}

/*
* モーダル
******************************/
.m-modal {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 101;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  transition: 0.1s linear;
  @include sp {
    padding: 20px;
  }
  &.active {
    opacity: 1;
    visibility: visible;
  }
  &__contents {
    position: relative;
    width: 100%;
    max-width: 800px;
    max-height: 80%;
    background: #fff;
    &__inner {
      padding: 60px;
      overflow-y: scroll;
      max-height: 700px;
      height: 100%;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      @include sp {
        overflow-y: auto;
        max-height: calc(100vh - 100px);
        padding: 40px 20px;
      }
    }
  }
  &__closebtn {
    position: absolute;
    right: 0;
    top: -37px;
    font-size: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 27px;
    transition: 0.1s linear;
    &__bottom {
      font-size: 1.6rem;
      color: #fff;
      margin-top: 20px;
      padding-left: 30px;
      position: relative;
      cursor: pointer;
      &:before {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        background: url(/assets/img/common/icon_close_white.png) center/contain
          no-repeat;
      }
    }
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
}

.m-modal--confirm {
  &__icon {
    width: 42px;
    height: 38px;
    margin: 0 auto;
  }
  .m-btn--normal {
    width: 240px;
    height: 70px;
    border-radius: 35px;
    flex-wrap: wrap;
    .m-text--big {
      line-height: 1.5;
    }
    span {
      display: block;
      width: 100%;
      font-size: 1.2rem;
      color: $c-sub;
      font-weight: normal;
    }
  }
  @include sp {
    .m-btn--normal {
      width: 245px;
      height: 60px;
    }
    .m-btn--2col .m-btn:nth-child(n + 2) {
      margin-top: 15px;
    }
  }
}

.m-col2 {
  display: flex;
  flex-wrap: wrap;
  &__item {
    width: calc((100% - 50px) / 2);
    margin-right: 50px;
    &:nth-child(2n) {
      margin-right: 0;
    }
    &:nth-child(n + 3) {
      margin-top: 50px;
    }
  }
  @include sp {
    &__item {
      width: 100%;
      margin-right: 0;
      &:nth-child(n + 2) {
        margin-top: 50px;
      }
    }
  }
}

.m-col3 {
  display: flex;
  flex-wrap: wrap;
  &__item {
    width: calc((100% - 80px) / 3);
    margin-right: 40px;
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:nth-child(n + 4) {
      margin-top: 15px;
    }
  }
  @include sp {
    &__item {
      width: 100%;
      margin-right: 0;
      &:nth-child(3n) {
        margin-top: 15px;
      }
      &:nth-child(n + 2) {
        margin-top: 15px;
      }
    }
  }
}

.m-col4 {
  display: flex;
  flex-wrap: wrap;
  &__item {
    width: calc((100% - 120px) / 4);
    margin-right: 40px;
    &:nth-child(4n) {
      margin-right: 0;
    }
    &:nth-child(n + 5) {
      margin-top: 15px;
    }
  }
  @include sp {
    &__item {
      width: 100%;
      margin-right: 0;
      &:nth-child(4n) {
        margin-top: 15px;
      }
      &:nth-child(n + 2) {
        margin-top: 15px;
      }
    }
  }
}

.m-introduction {
  display: flex;
  position: relative;
  padding-top: 50px;
  &__img {
    width: 350px;
    min-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 35px 0px rgba(2, 34, 110, 0.12);
    position: absolute;
    top: 0px;
    z-index: 1;
    img {
      width: 100%;
      object-fit: contain;
      max-height: 350px;
    }
  }
  &__detail {
    margin-left: 300px;
    width: calc(100% - 300px);
    padding: 50px 50px 50px 100px;
    background: #f5f6f9;
    @include pc {
      min-height: 350px;
    }
  }
  &__ttl {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 15px;
  }
  &__subtxt {
    font-size: 1.6rem;
    line-height: 1.6;
    color: $c-sub;
    + * {
      margin-top: 20px;
    }
  }
  &__txt {
    font-size: 1.5rem;
    line-height: 2;
    + .m-introduction__txt {
      margin-top: 15px;
    }
  }
  @include sp {
    display: block;
    position: relative;
    padding-top: 245px;
    &__img {
      width: 100%;
      box-shadow: none;
      min-height: inherit;
      position: absolute;
      top: 0px;
      z-index: 1;
    }
    &__detail {
      margin: 0 -15px;
      width: calc(100% + 30px);
      padding: 130px 15px 50px;
    }
    &__ttl {
      font-size: 2rem;
      margin-bottom: 10px;
    }
    &__subtxt {
      font-size: 1.4rem;
      + * {
        margin-top: 15px;
      }
    }
  }
}

.m-banner {
  &__link {
    display: block;
    box-shadow: 0 0 35px rgba(2, 34, 110, 0.12);
    transition: $transition;
    &:hover {
      box-shadow: none;
      opacity: 0.8;
    }
  }
}

@media screen and (max-width: 768px) {
  .m-banner-img {
    &__switch {
      margin-top: 30px;
      margin-bottom: 30px;
      .m-banner {
          &__link {
          max-width: 345px;
          margin-left: auto;
          margin-right: auto;
          padding-top: inherit;
          height: 457px;
        }
      }
    }
  }
}

.m-products-card {
  &__item {
    background: #fff;
    box-shadow: 0px 0px 35px 0px rgba(2, 34, 110, 0.12);
    padding: 60px 50px;
    &:nth-child(n + 2) {
      margin-top: 50px;
    }
  }
  &__ttl {
    font-size: 2.4rem;
    font-weight: 500;
    text-align: center;
  }
  &__subttl {
    font-size: 1.8rem;
    line-height: 1.6;
    color: $c-accent;
    margin-bottom: 10px;
    &:nth-of-type(n + 2) {
      margin-top: 30px;
    }
  }
  &__subttl--blue {
    font-size: 2rem;
    color: $c-accent;
    font-weight: 500;
    margin-bottom: 25px;
  }
  &__detail {
    margin-top: 25px;
    display: flex;
    &__left {
      width: 350px;
      margin-right: 50px;
    }
    &__right {
      width: calc(100% - 400px);
    }
  }
  &__plan {
    &__detail {
      padding: 25px 0;
      border-top: 1px solid #dadae0;
      &:last-child {
        padding-bottom: 0;
      }
      &__tag {
        background-image: -webkit-linear-gradient(
          -180deg,
          rgb(87, 197, 207) 0%,
          rgb(73, 169, 214) 24%,
          rgb(58, 140, 220) 50%,
          rgb(49, 118, 206) 79%,
          rgb(39, 96, 191) 100%
        );
        color: #fff;
        font-size: 1.3rem;
        padding: 3px 12px;
        display: inline-block;
        text-align: center;
        margin-bottom: 10px;
      }
      &__plan {
        font-size: 1.7rem;
        font-weight: 500;
      }
      &__spec {
        font-size: 1.3rem;
        font-weight: 500;
        color: #5a6880;
      }
      &__speclist {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        &__title {
          font-size: 1.6rem;
          font-weight: normal;
          width: 140px;
        }
        &__data {
          font-size: 1.6rem;
          font-weight: normal;
          width: calc(100% - 140px);
        }
      }
    }
    &__flex {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__period {
      font-size: 1.4rem;
      color: $c-sub;
      width: 90px;
      margin-top: 7px;
    }
    &__price {
      font-size: 1.4rem;
      font-weight: 500;
      width: calc(100% - 90px);
      text-align: right;
      &__num {
        display: inline-block;
        margin-left: 10px;
        font-size: 2.6rem;
        font-weight: 500;
        color: $c-red;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 900px) {
    &__detail {
      &__left {
        width: 50%;
      }
      &__right {
        width: calc(50% - 50px);
      }
      &__speclist {
        &__title {
          font-size: 1.5rem;
        }
        &__data {
          font-size: 1.5rem;
        }
      }
    }
    &__plan {
      &__flex {
        display: flex;
        flex-wrap: wrap;
      }
      &__period {
        width: 100%;
      }
      &__price {
        width: 100%;
      }
    }
  }
  @include sp {
    &__item {
      padding: 30px 20px;
      &:nth-child(n + 2) {
        margin-top: 30px;
      }
    }
    &__ttl {
      font-size: 2rem;
      text-align: left;
    }
    &__subttl {
      font-size: 1.7rem;
      &:nth-of-type(n + 2) {
        margin-top: 25px;
      }
    }
    &__subttl--blue {
      font-size: 1.7rem;
      margin-bottom: 20px;
    }
    &__detail {
      margin-top: 20px;
      flex-wrap: wrap;
      &__left {
        width: 100%;
        margin-right: 0;
      }
      &__right {
        margin-top: 30px;
        width: 100%;
      }
    }
    &__plan {
      &__detail {
        padding: 20px 0;
        &__plan {
          font-size: 1.6rem;
        }
      }
      &__flex {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
      }
      &__period {
        width: 100%;
        margin-top: 0;
      }
      &__price {
        width: 100%;
      }
    }
  }
}
@media print {
  .m-tab__index {
    position: relative;
  }
}